import { ROUTES } from './routes'
/*
--GreenIO页面级变量--
pageTitle_pvar: 页面标题
pageType_pvar: 页面类型（直播课程，专栏课程，面授课程，音频课程，文章，律协，工具）

--埋点页面--
已埋：
登录页、注册页、无讼学院页、课程反馈页、课程搜索页、直播列表页、标签列表页、课程详情页（6种）、专栏列表页、
秒杀课程列表页、拼团课程列表页、拼团详情页、律协专区首页、课程学习页、优惠券页、兑换码页、热门课程榜页
拼团列表页、

未埋或不涉及：
首页（之前叫APP首页）、文章推荐页、文章详情页、专栏文章列表页、工具页、充值页、案例检索页、案例详情页、
协作广场页、协作订单详情页、发单类型选择页、发单详情编辑页、我的协作订单页、律师认证页、
*/
export const GIO_PAGE_VARS = [
    {
        path: ROUTES.LOGIN,
        params: {
            pageTitle_pvar: '登录页',
            pageType_pvar: '无'
        }
    },
    {
        path: ROUTES.REGISTER,
        params: {
            pageTitle_pvar: '注册页',
            pageType_pvar: '无'
        }
    },
    {
        path: ROUTES.ROOT,
        params: {
            pageTitle_pvar: '无讼学院页',
            pageType_pvar: '无'
        }
    },
    {
        path: ROUTES.HOME,
        params: {
            pageTitle_pvar: '无讼学院页',
            pageType_pvar: '无'
        }
    },
    {
        path: ROUTES.EVALUATE,
        params: {
            pageTitle_pvar: '课程反馈页',
            pageType_pvar: '无'
        }
    },
    {
        path: ROUTES.COLUMN_MORE,
        params: {
            pageTitle_pvar: '专栏列表页',
            pageType_pvar: '专栏课程'
        }
    },
    {
        path: ROUTES.SEARCH_COURSE,
        params: {
            pageTitle_pvar: '课程搜索页',
            pageType_pvar: '无'
        }
    },
    {
        path: ROUTES.LIVE_MORE,
        params: {
            pageTitle_pvar: '直播列表页',
            pageType_pvar: '直播课程'
        }
    },
    {
        path: ROUTES.LABEL_COURSE_LIST,
        params: {
            pageTitle_pvar: '标签列表页',
            pageType_pvar: '无'
        }
    },
    {
        path: ROUTES.LIVE_DETAIL,
        params: {
            pageTitle_pvar: '课程详情页',
            pageType_pvar: '直播课程'
        }
    },
    {
        path: ROUTES.VIDEO_DETAIL,
        params: {
            pageTitle_pvar: '课程详情页',
            pageType_pvar: '视频课程'
        }
    },
    {
        path: ROUTES.FACE2FACE_DETAIL,
        params: {
            pageTitle_pvar: '课程详情页',
            pageType_pvar: '面授课程'
        }
    },
    {
        path: ROUTES.COLLECTION_DETAIL,
        params: {
            pageTitle_pvar: '课程详情页',
            pageType_pvar: '课程合辑'
        }
    },
    {
        path: ROUTES.AUDIO_DETAIL,
        params: {
            pageTitle_pvar: '课程详情页',
            pageType_pvar: '音频课程'
        }
    },
    {
        path: ROUTES.COLUMN_DETAIL,
        params: {
            pageTitle_pvar: '课程详情页',
            pageType_pvar: '专栏课程'
        }
    },
    {
        path: ROUTES.SECKILL_LIST,
        params: {
            pageTitle_pvar: '秒杀课程列表页',
            pageType_pvar: '无'
        }
    },
    {
        path: ROUTES.GROUP_BUYING_LIST,
        params: {
            pageTitle_pvar: '拼团列表页',
            pageType_pvar: '无'
        }
    },
    {
        path: ROUTES.GROUP_BUYING_DETAIL,
        params: {
            pageTitle_pvar: '拼团详情页',
            pageType_pvar: '无'
        }
    },
    {
        path: ROUTES.LAW_SOCIETY_SECTION,
        params: {
            pageTitle_pvar: '律协专区首页',
            pageType_pvar: '无'
        }
    },
    {
        path: ROUTES.MY_COURSE_LIST,
        params: {
            pageTitle_pvar: '课程学习页',
            pageType_pvar: '无'
        }
    },
    {
        path: ROUTES.VOUCHER_DETAIL,
        params: {
            pageTitle_pvar: '优惠券页',
            pageType_pvar: '无'
        }
    },
    {
        path: ROUTES.REDEEM,
        params: {
            pageTitle_pvar: '兑换码页',
            pageType_pvar: '无'
        }
    },
    {
        path: ROUTES.TOP_COURSE_LIST,
        params: {
            pageTitle_pvar: '热门课程榜页',
            pageType_pvar: '无'
        }
    }
]
