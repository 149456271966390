import styled from 'styled-components'
import { relativeLength } from '../../utils/layout'
import { Input } from 'antd'
import { Link } from 'react-router-dom'
import { Animation, PrimarySquareButton } from '../common/styles'

export const PageStyle = styled(Animation)`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    padding: ${relativeLength(112)} ${relativeLength(80)} ${relativeLength(80)};
    width: 100%;
    height: 100%;
    background: #fff;
`
export const Title = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin-bottom: ${relativeLength(20)};
    width: 100%;

    font-size: ${relativeLength(40)};
    font-weight: 600;
    color: #333333;
    line-height: ${relativeLength(56)};

    .title {
    }
    .action {
        font-size: ${relativeLength(26)};
        font-weight: 400;
        color: #4187f2;
        line-height: ${relativeLength(36)};
        white-space: nowrap;
        > .anticon {
            transform: scaleX(0.6);
        }
    }
`
export const Line = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: ${relativeLength(30)} auto;
    width: 100%;

    > * {
        margin: auto;
    }
`
export const ErrorMessage = styled.div`
    flex: auto;
    font-size: ${relativeLength(26)};
    font-weight: 400;
    color: #ff6666;
    line-height: ${relativeLength(36)};
`
export const SecondaryLink = styled(Link)`
    font-size: ${relativeLength(26)};
    font-weight: 400;
    color: #4187f2;
    line-height: ${relativeLength(36)};
`
export const SecondaryALink = styled.a`
    font-size: ${relativeLength(26)};
    font-weight: 400;
    color: #4187f2;
    line-height: ${relativeLength(36)};
`
export const InputStyle = styled(Input)`
    && {
        padding: ${relativeLength(30)} ${relativeLength(0)} ${relativeLength(30)};
        height: ${relativeLength(120)};
        background: none;
        border: none;
    }
    &&:active,
    &&:focus,
    &&:hover {
        outline: none;
        box-shadow: none;
    }
    &&.ant-input,
    input.ant-input {
        background: transparent;
        font-size: ${relativeLength(32)};
        font-weight: 400;
        color: #333333;
        line-height: ${relativeLength(44)};
    }
    && .ant-input-suffix .anticon svg {
        width: ${relativeLength(34)};
        height: ${relativeLength(34)};
    }
    &&.ant-input-affix-wrapper {
        background-color: transparent;
        outline: none;
        box-shadow: none;
        > input {
            background-color: transparent;
        }
    }
    && .icon {
        display: inline-block;
        width: ${relativeLength(48)};
        height: ${relativeLength(48)};
    }
`
export const PasswordStyle = styled(Input.Password)`
    && {
        padding: ${relativeLength(30)} ${relativeLength(0)} ${relativeLength(30)};
        height: ${relativeLength(120)};
        background: none;
        border: none;
    }
    &&:active,
    &&:focus,
    &&:hover {
        outline: none;
        box-shadow: none;
    }
    &&.ant-input,
    input.ant-input {
        background: transparent;
        font-size: ${relativeLength(32)};
        font-weight: 400;
        color: #333333;
        line-height: ${relativeLength(44)};
    }
    && .ant-input-suffix img {
        width: ${relativeLength(48)};
        height: ${relativeLength(48)};
    }
    &&.ant-input-affix-wrapper {
        background-color: transparent;
        outline: none;
        box-shadow: none;
        > input {
            background-color: transparent;
        }
    }
    && .icon {
        display: inline-block;
        width: ${relativeLength(48)};
        height: ${relativeLength(48)};
    }
`
export const FormStyle = styled.div`
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;

    width: 100%;

    .ant-form-item {
        position: relative;
        margin-bottom: 0;
        border-bottom: ${relativeLength(1)} solid #dbdbdb;
    }

    > ${PrimarySquareButton} {
        display: block;
        margin-top: ${relativeLength(20)};
    }
    .note {
        display: inline-block;
        margin-top: ${relativeLength(56)};
    }
`
export const Note = styled.div`
    font-size: ${relativeLength(24)};
    font-weight: 400;
    color: #999999;
    line-height: ${relativeLength(34)};
    text-align: center;
`
export const Placeholder = styled.div`
    flex: 1;
`

export const AgreementStyle = styled.div`
    padding: ${relativeLength(40)} ${relativeLength(24)};
    width: 100%;
    height: 100%;
    background: #fff;

    font-size: ${relativeLength(28)};
    font-weight: 400;
    color: #3c3d3c;
    line-height: ${relativeLength(56)};

    overflow-y: auto;
    overflow-x: hidden;
    b {
        font-weight: 600;
    }

    > .scrollable {
        > * {
            margin-bottom: ${relativeLength(24)};
        }
    }
    h1 {
        display: block;
        margin-bottom: ${relativeLength(36)};
        font-size: ${relativeLength(32)};
        font-weight: 600;
        color: #3c3d3c;
        line-height: ${relativeLength(44)};
        text-align: center;
    }
    h2 {
        margin-bottom: ${relativeLength(24)};
        color: #3c3d3c;
        line-height: ${relativeLength(40)};
        text-align: left;
    }
    p {
        margin-bottom: ${relativeLength(24)};
    }
    span {
        text-decoration: underline;
    }
    h2,
    strong {
        font-weight: 600;
        font-size: ${relativeLength(28)};
    }
    table {
        border: ${relativeLength(1)} solid #000;
        tr:not(:last-child) {
            border-bottom: ${relativeLength(1)} solid #000;
        }
        th,
        td {
            &:not(:last-child),
            &:not(:first-child) {
                border-right: ${relativeLength(1)} solid #000;
            }
        }
    }
    i {
        color: rgb(0, 176, 240);
    }
`
