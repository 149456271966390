import { useEffect } from 'react'

export function useRefreshBFCache(): void {
    useEffect(() => {
        const refresh = function (e: PageTransitionEvent): void {
            // 通过persisted属性判断是否存在 BF Cache
            if (e.persisted) {
                window.location.reload()
            }
        }
        window.addEventListener('pageshow', refresh)

        return () => window.removeEventListener('pageshow', refresh)
    }, [])
}
