import React from 'react'
import reactDOM from 'react-dom'
import Routers from './routes'
import store from './store'
import { Provider } from 'react-redux'
import * as serviceWorker from './serviceWorker'
import './reset.less'
;((doc, win) => {
    const docEl = doc.documentElement,
        resizeEvt = 'onorientationchange' in window ? 'onorientationchange' : 'resize',
        recalc = function () {
            const clientWidth = docEl.clientWidth
            if (!clientWidth) return
            let fontSize
            const zoom = clientWidth / 750
            if (clientWidth >= 1500) {
                fontSize = 200
            } else if (zoom < 0.5) {
                fontSize = 50
            } else {
                fontSize = 100 * (clientWidth / 750)
            }
            docEl.style.fontSize = fontSize + 'px'
        }

    if (!win.addEventListener) return

    win.addEventListener(resizeEvt, recalc, false)
    win.addEventListener('DOMContentLoaded', recalc, false)
})(document, window)

reactDOM.render(
    <Provider store={store}>
        <Routers />
    </Provider>,
    document.getElementById('root')
)

serviceWorker.unregister()
