import styled from 'styled-components'
import { Button } from 'antd'
import { relativeLength } from '../../utils/layout'
import AddFavoriteIcon from './images/add-favorite.svg'
import AddFavoriteIconActive from './images/add-favorite-active.svg'
import { FROM_TYPE } from '../../constants/types'

export const Animation = styled.div`
    @keyframes slide-up {
        0% {
            transform: translateY(100%);
        }
        100% {
            transform: translateY(0);
        }
    }

    &.--slide-up {
        animation: slide-up ease-out 200ms forwards;
    }
    &.--slide-down {
        animation: slide-up ease-out 200ms forwards reverse;
    }
`
export const Gap = styled.div`
    height: ${relativeLength(10)};
    border-top: ${relativeLength(1)} solid #eee;
    border-bottom: ${relativeLength(1)} solid #eee;
    background-color: #f3f3f3;
`
export const TabStyle = styled.div`
    flex: 1;
    position: relative;
    height: 100%;
    min-height: 100px;
    background: #fff;
    border-top: 1px solid rgba(249, 249, 249, 1);
`
export const PrimaryButtonStyle = styled(Button)`
    && {
        flex: 0 0 auto;

        display: flex;
        justify-content: center;
        align-items: center;

        padding: 0;

        height: ${relativeLength(88)};
        background: #4187f2;
        border: none;
        border-radius: ${relativeLength(44)};

        font-size: ${relativeLength(30)};
        font-weight: 400;
        color: rgba(255, 255, 255, 1);
        line-height: ${relativeLength(36)};
        text-shadow: none;

        &:hover,
        &:focus,
        &:active {
            background: #4187f2;
            color: rgba(255, 255, 255, 1);
        }
    }

    &&[disabled] {
        background: rgba(65, 135, 242, 0.5);
        border: none;
        color: #fff;

        &:hover,
        &:focus,
        &:active {
            background: rgba(65, 135, 242, 0.5);
            color: #fff;
        }
    }

    &&.shadow {
        box-shadow: 0 ${relativeLength(8)} ${relativeLength(24)} ${relativeLength(-16)} rgba(65, 135, 242, 0.5);

        &:hover,
        &:focus,
        &:active {
            box-shadow: 0 ${relativeLength(8)} ${relativeLength(24)} ${relativeLength(-16)} rgba(65, 135, 242, 0.5);
        }
    }
`
export const NormalButtonStyle = styled(PrimaryButtonStyle)`
    && {
        color: #666666;
        background: rgba(240, 240, 240, 1);
        border: none;
        --antd-wave-shadow-color: #aaa;

        &:hover,
        &:focus,
        &:active {
            color: #666666;
            background: rgba(240, 240, 240, 1);
            border: none;
        }

        &&.shadow {
            box-shadow: none;
            &:hover,
            &:focus,
            &:active {
                box-shadow: none;
            }
        }
    }
`

export const PrimaryGhostButtonStyle = styled(PrimaryButtonStyle)`
    && {
        background-color: transparent;
        border: ${relativeLength(1)} solid #4187f2;
        color: #4187f2;
        box-shadow: none;

        &:hover,
        &:focus,
        &:active {
            background: transparent;
            border: ${relativeLength(1)} solid #4187f2;
            color: #4187f2;
        }

        &[disabled] {
            background: transparent;
            border: ${relativeLength(1)} solid #ccc;
            color: #bbb;

            &:hover,
            &:focus,
            &:active {
                background: transparent;
                border-color: #ccc;
                color: #bbb;
            }
        }
    }
`

export const PrimaryGradientButtonStyle = styled(PrimaryButtonStyle)`
    &&,
    &&:hover,
    &&:focus,
    &&:active {
        height: ${relativeLength(76)};
        background: linear-gradient(86deg, #62a7f6 0%, #4187f2 100%);
        box-shadow: 0 ${relativeLength(16)} ${relativeLength(24)} ${relativeLength(-16)} rgba(65, 135, 242, 0.5);

        font-size: ${relativeLength(30)};
        font-weight: 500;
        color: #ffffff;
        line-height: ${relativeLength(48)};
    }
`

export const PrimarySquareButton = styled(Button)`
    && {
        flex: 0 0 auto;
        height: ${relativeLength(88)};
        background: #4187f2;
        border: none;
        border-radius: ${relativeLength(8)};

        font-size: ${relativeLength(30)};
        font-weight: 400;
        color: rgba(255, 255, 255, 1);
        line-height: ${relativeLength(36)};
        text-shadow: none;

        &:hover,
        &:focus,
        &:active {
            background: #4187f2;
            color: rgba(255, 255, 255, 1);
        }
    }

    &&[disabled] {
        background: #85b8df;
        border: none;
        color: #fff;

        &:hover,
        &:focus,
        &:active {
            background: #85b8df;
            color: #fff;
        }
    }

    &&.shadow {
        box-shadow: 0 ${relativeLength(8)} ${relativeLength(24)} ${relativeLength(-16)} rgba(65, 135, 242, 0.5);

        &:hover,
        &:focus,
        &:active {
            box-shadow: 0 ${relativeLength(8)} ${relativeLength(24)} ${relativeLength(-16)} rgba(65, 135, 242, 0.5);
        }
    }
`
export const PrimarySquareGhostButton = styled(PrimarySquareButton)`
    && {
        height: ${relativeLength(88)};
        background: #f1f7ff;
        border-radius: ${relativeLength(8)};
        border: ${relativeLength(2)} solid #b9d1fe;

        font-size: ${relativeLength(30)};
        font-weight: 400;
        color: #4187f2;
        line-height: ${relativeLength(42)};

        &:hover,
        &:active,
        &:focus {
            background: #f1f7ff;
            border: ${relativeLength(2)} solid #b9d1fe;
            color: #4187f2;
        }
        &[disabled],
        &[disabled]:hover,
        &[disabled]:active,
        &[disabled]:focus {
            background: #f2f2f2;
            border: ${relativeLength(2)} solid transparent;
            color: #b3b3b3;
        }
    }
`

/* 课程详情、直播互动、专栏等 tab 样式 */
export const AntMobileTabsStyle = styled.div`
    flex: 1;
    .am-tabs {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: stretch;

        height: 100%;

        > .am-tabs-tab-bar-wrap {
            flex: 0 0 auto;
            .am-tabs-default-bar-tab-active {
                color: #333;
                &:before {
                    content: '';
                    width: ${relativeLength(34)};
                    height: ${relativeLength(6)};
                    border-radius: ${relativeLength(6)};
                    position: absolute;
                    left: 50%;
                    transform: translateX(-50%);
                    bottom: 0;
                    background: linear-gradient(90deg, #69b4fc, #4187f2);
                    z-index: 2;
                }
            }
            .am-tabs-default-bar-underline {
                display: none;
            }
        }
        > .am-tabs-content-wrap {
            flex: 1;
            .am-tabs-pane-wrap {
                display: flex;
                flex-direction: column;
                align-items: stretch;
            }
        }
    }
`

export const AddToCollectionIcon = styled.div<{ collected: boolean; fromType: string }>`
    position: absolute;
    top: ${relativeLength(20)};
    right: ${relativeLength(30)};
    width: ${relativeLength(56)};
    height: ${relativeLength(56)};
    display: ${props => (props.fromType === FROM_TYPE.APP ? 'none' : 'inline-block')};
    background-image: ${props => (props.collected ? `url(${AddFavoriteIconActive})` : `url(${AddFavoriteIcon})`)};
    background-repeat: no-repeat;
    z-index: 2;
`
