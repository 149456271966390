import { CHANGE_COURSEID, CHANGE_PAY_QRCODE } from '../action/action.js'
import { CHANGE_COURSE_TYPE } from '../action/action'

const initWebLiveState = {
    courseId: '',
    courseType: '',
    qrcodeStatus: false
}

function changeCourseId(state = initWebLiveState, action) {
    const newState = Object.assign({}, state)
    switch (action.type) {
        case CHANGE_COURSEID:
            newState.courseId = action.id
            return newState
        case CHANGE_PAY_QRCODE:
            newState.qrcodeStatus = action.status
            return newState
        case CHANGE_COURSE_TYPE:
            newState.courseType = action.value
            return newState
        default:
            return state
    }
}

const webLiveReducer = {
    webLiveDetail: changeCourseId
}

export default webLiveReducer
