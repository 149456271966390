import { useHistory } from 'react-router-dom'
import React, { useEffect } from 'react'
import QueryString from 'qs'
import Axios from 'axios'
import { WSResponseType } from '../../constants/types'
import { WEIXIN_LOGIN, WEIXIN_OAUTH } from '../../constants/api'
import { saveSession } from '../common/SessionValidator'
import { Toast } from 'antd-mobile'
import { LoginResponse } from './index'
import { ROUTES } from '../../constants/routes'
import URI from 'urijs'

export enum WEIXIN_AUTH_OPERATION {
    /** 只获取授权，返回授权结果 */
    AUTH_ONLY = 'auth_only',
    /** 授权后微信登录并绑定手机 */
    AUTH_AND_LOGIN = 'auth_n_login'
}
export const WEIXIN_OAUTH_DATA = 'WEIXIN_OAUTH_DATA' // 授权信息存储 key

type WeixinAuthDataType = {
    openId: string
    unionId: string
    state: string
    nickName: string
    headImgUrl: string
    sex: string
}
function saveWeixinAuthData(params: WeixinAuthDataType): void {
    sessionStorage.setItem(
        WEIXIN_OAUTH_DATA,
        JSON.stringify({
            openId: params.openId,
            unionId: params.unionId,
            nickName: params.nickName,
            headImgUrl: params.headImgUrl,
            sex: params.sex
        })
    )
}
export function getWeixinAuthData(): WeixinAuthDataType {
    let auth
    try {
        auth = JSON.parse(sessionStorage.getItem(WEIXIN_OAUTH_DATA) || '{}')
    } catch (e) {
        auth = {}
        console.error(e)
    }
    return auth
}
export function clearWeixinAuthData(): void {
    sessionStorage.removeItem(WEIXIN_OAUTH_DATA)
}

export const WEIXIN_REDIRECT_TARGET = 'WEIXIN_REDIRECT_TARGET'
export type WeixinLoginResponse =
    | {
          needBind: true
          userDTO: null
          userId: string
      }
    | {
          needBind: false
          userDTO: LoginResponse
          userId: string
      }

/**
 * 微信授权结果处理页面
 * @example
 * // 发起授权链接使用当前页面地址，例如
 * ROUTES.OAUTH_WEIXIN
 * @example
 * // 授权后跳转地址使用
 * sessionStorage.setItem(WEIXIN_REDIRECT_TARGET, target)
 * // 来设置
 */
export default function WeixinAuth(): JSX.Element {
    const history = useHistory()
    const onGio = (): void => {
        window.gio('track', 'loginSuccess', { loginMethod_var: '微信登录' })
    }

    useEffect(() => {
        type Params = {
            op: WEIXIN_AUTH_OPERATION
            openId: string
            unionId: string
            state: string
            nickName: string
            headImgUrl: string
            sex: string
        }
        const params = QueryString.parse(history.location.search.substring(1))
        for (const key of Object.keys(params)) {
            if (params[key] === 'null') {
                delete params[key]
            }
        }

        /**
         * 保存授权
         * !! 授权信息使用后需及时清除
         */
        if (params.unionId && params.openId) {
            saveWeixinAuthData(params as WeixinAuthDataType)
        }

        if (params.op === WEIXIN_AUTH_OPERATION.AUTH_AND_LOGIN) {
            /* 微信登录 */
            if (params.unionId) {
                Axios.post<WSResponseType<WeixinLoginResponse>>(WEIXIN_LOGIN, {
                    unionId: params.unionId,
                    openId: params.openId
                })
                    .then(res => res.data)
                    .then(({ code, message, data }) => {
                        if (code === 0) {
                            onGio()
                            window.gio('setUserId', data.userId)
                            if (data.needBind) {
                                // redirect /bindPhone
                                const target = sessionStorage.getItem(WEIXIN_REDIRECT_TARGET)
                                if (target) {
                                    /* afterLoginURL: 授权登录后的页面地址 */
                                    history.replace(ROUTES.BIND_PHONE + `?afterLoginURL=${encodeURIComponent(target)}`)
                                } else {
                                    history.replace(ROUTES.BIND_PHONE)
                                }
                            } else {
                                saveSession(data.userDTO)
                                clearWeixinAuthData()
                                // after login
                                const target = sessionStorage.getItem(WEIXIN_REDIRECT_TARGET)
                                if (target) {
                                    window.location.replace(target)
                                    // history.replace(target);
                                } else {
                                    history.replace(ROUTES.HOME)
                                }
                            }
                        } else {
                            Toast.fail(message)
                        }
                    })
            } else {
                console.error('wx auth failed')
            }
        } else if (params.op === WEIXIN_AUTH_OPERATION.AUTH_ONLY) {
            if (params.unionId) {
                const target = sessionStorage.getItem(WEIXIN_REDIRECT_TARGET)
                if (target) {
                    /*
                     * afterLoginURL: 授权登录后的页面地址，携带授权信息 query
                     * 静默授权如果是同一页面跳转会生成两个浏览历史，需手动消除第二个历史
                     */
                    const url = new URI(target)
                    for (const key of Object.keys(params)) {
                        url.addSearch(key, params[key])
                    }
                    window.location.replace(url.href())
                } else {
                    console.error('redirection after wx auth was not specified')
                }
            }
        } else {
            console.error('unknown wx auth operation')
        }
        return () => {
            sessionStorage.removeItem(WEIXIN_REDIRECT_TARGET)
        }
    }, [history, history.location.search])

    return <></>
}

/**
 * 发起微信授权动作
 * <br/>授权后会自动微信登录
 * @param scope 微信授权模式 用户信息 snsapi_userinfo / 静默授权 snsapi_base
 * @param op 跳转后操作方式
 * @param afterLoginPath 授权登录后跳转路径
 * @example
 * /wpment?courseId=12345&courseType=45678
 */
export function wxAuthRedirect(scope: string, op: WEIXIN_AUTH_OPERATION, afterLoginPath?: string): void {
    /* 缓存微信登录后跳转地址 */
    if (afterLoginPath) {
        sessionStorage.setItem(WEIXIN_REDIRECT_TARGET, afterLoginPath)
    }
    /* 后端固定跳转学院域名，state 只提供 path */
    const authApi = new URI({
        protocol: 'https',
        hostname: process.env.REACT_APP_WX_OAUTH,
        path: WEIXIN_OAUTH
    })
        .addQuery('scope', scope)
        .addQuery(
            'state',
            encodeURIComponent(
                JSON.stringify({
                    /* 微信授权后的跳转地址，携带用户信息
                     * 此处跳转至当前页面，根据 op 区分下一步动作 */
                    url: `${ROUTES.OAUTH_WEIXIN}?op=${op}`,
                    /* 微信授权跳转环境 */
                    env: process.env.REACT_APP_WX_OAUTH_ENV
                })
            )
        )
        .toString()
    window.location.replace(authApi)
}
