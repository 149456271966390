import React, { PropsWithChildren, useEffect, useLayoutEffect, useState } from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import logo from './images/logo.svg'
import weixin from './images/weixin.svg'
import eyeOpen from './images/eye-open.svg'
import eyeClosed from './images/eye-closed.svg'
import { relativeLength } from '../../utils/layout'
import { Form } from 'antd'
import { useHistory } from 'react-router-dom'
import {
    ErrorMessage,
    FormStyle,
    InputStyle,
    Line,
    PageStyle,
    PasswordStyle,
    Placeholder,
    SecondaryALink
} from './styles'
import { WEB_LOGIN } from '../../constants/api'
import { ROUTES } from '../../constants/routes'
import { CloseOutlined } from '@ant-design/icons'
import Axios from 'axios'
import { WSResponseType } from '../../constants/types'
import { saveSession } from '../common/SessionValidator'
import QueryString from 'qs'
import { WEIXIN_AUTH_OPERATION, wxAuthRedirect } from './WeixinAuth'
import { PrimarySquareButton } from '../common/styles'

const GlobalStyle = createGlobalStyle`
    html, body {
        width: 100%;
        height: 100%;
    }
    html * {
        box-sizing: border-box;
    }
`
const Logo = styled.div`
    flex: 0 0 auto;
    > .logo {
        margin: auto;
        width: ${relativeLength(280)};
        height: ${relativeLength(128)};
        background: transparent url(${logo}) no-repeat center;
        background-size: ${relativeLength(280)} ${relativeLength(128)};
    }
    > .subtitle {
        margin-top: ${relativeLength(28)};
        font-size: ${relativeLength(24)};
        font-weight: 400;
        color: #888888;
        line-height: ${relativeLength(34)};
        letter-spacing: ${relativeLength(5)};
    }
`
const WeixinLogin = styled.div`
    flex: 0 0 auto;

    font-size: ${relativeLength(26)};
    font-weight: 400;
    color: #45cd8a;
    line-height: ${relativeLength(36)};

    .icon {
        margin: auto;
        width: ${relativeLength(68)};
        height: ${relativeLength(68)};
        background: transparent url(${weixin}) no-repeat center;
        background-size: ${relativeLength(68)} ${relativeLength(68)};
        border-radius: 50%;
    }
    .text {
        margin-top: ${relativeLength(20)};
    }
`
const Close = styled.div`
    position: absolute;
    top: ${relativeLength(40)};
    right: ${relativeLength(50)};
    width: ${relativeLength(30)};
    height: ${relativeLength(30)};

    color: #dcdcdc;
    font-size: ${relativeLength(30)};
`

export type LoginResponse = {
    userId: string
    name: string
    phone: string
    email?: string
    photo: string
    token: string
    goslingUserId?: string
    wsUser: boolean
    unionId?: string
}

export type LoginProps = {
    afterLoginURL?: string // 登录重定向地址
    onLogin?: () => void
    onCancel?: () => void
    transition?: boolean // 进场特效
    phone?: string
}

function waitForAnimation(callback: () => void): void {
    setTimeout(() => {
        requestAnimationFrame(callback)
    }, 200)
}

function Login(props: PropsWithChildren<LoginProps>): JSX.Element {
    const history = useHistory()
    const [animationClass, setAnimation] = useState('')
    const [form] = Form.useForm()
    const [message, setMessage] = useState<string>()
    const [afterLoginURL, setAfterLoginURL] = useState<string>()

    useEffect(() => {
        const params = QueryString.parse(history.location.search.substring(1))
        if (params.phone) {
            form.setFieldsValue({ phone: params.phone })
        }
        if (props.afterLoginURL) {
            setAfterLoginURL(props.afterLoginURL)
        } else if (params.afterLoginURL) {
            setAfterLoginURL(params.afterLoginURL as string)
        }
    }, [])

    async function login(): Promise<void> {
        sessionStorage.setItem('showAd', 'true')
        setMessage('')
        let values
        try {
            values = await form.validateFields()
        } catch (e) {
            console.error(e)
            return
        }

        const {
            data: { code, message, data }
        } = await Axios.post<WSResponseType<LoginResponse>>(WEB_LOGIN, values)

        if (code === 0) {
            onGio()
            saveSession(data)
            console.log(data, 'datadatadata')
            window.gio('setUserId', data.userId)
            setAnimation('--slide-down')
            setTimeout(
                () =>
                    requestAnimationFrame(() => {
                        if (typeof afterLoginURL === 'string') {
                            // 地址登录重定向（刷新页面）
                            window.location.href = afterLoginURL
                            // history.replace(afterLoginURL);
                        } else {
                            history.push(ROUTES.HOME)
                        }

                        props.onLogin?.()
                    }),
                200
            ) // 动画延迟
        } else {
            setMessage(message || '登录失败')
        }
    }

    const onGio = (): void => {
        window.gio('track', 'loginSuccess', { loginMethod_var: '账号密码登录' })
    }

    useLayoutEffect(() => {
        if (props.transition) {
            setAnimation('--slide-up')
            waitForAnimation(() => setAnimation(''))
        }
    }, [props.transition])

    return (
        <PageStyle className={animationClass}>
            <GlobalStyle />
            {props.onCancel ? (
                <Close
                    onClick={() => {
                        setAnimation('--slide-down')
                        waitForAnimation(() => props.onCancel?.())
                    }}
                >
                    <CloseOutlined />
                </Close>
            ) : null}
            <Logo>
                <div className="logo" />
                <div className="subtitle">让法律更好地服务每个人</div>
            </Logo>
            <FormStyle style={{ marginTop: relativeLength(62) }}>
                <Form form={form}>
                    <Form.Item name={'phone'} help={null}>
                        <InputStyle placeholder={'手机号/无讼案例账号'} allowClear />
                    </Form.Item>
                    <Form.Item name={'password'} help={null}>
                        <PasswordStyle
                            placeholder={'请输入密码'}
                            iconRender={visible =>
                                visible ? (
                                    <img src={eyeOpen} alt="" className="icon" />
                                ) : (
                                    <img src={eyeClosed} alt="" className="icon" />
                                )
                            }
                            onPressEnter={() => login()}
                        />
                    </Form.Item>
                </Form>
                <Line>
                    <ErrorMessage>{message}</ErrorMessage>
                    <SecondaryALink
                        onClick={() => {
                            setAnimation('--slide-down')
                            waitForAnimation(() => props.onCancel?.())
                            history.push({
                                pathname: ROUTES.RESET_PASSWORD,
                                search: QueryString.stringify({
                                    afterLoginURL: afterLoginURL
                                })
                            })
                        }}
                    >
                        忘记密码?
                    </SecondaryALink>
                </Line>
                <PrimarySquareButton block onClick={() => login()}>
                    立即登录
                </PrimarySquareButton>
                <div style={{ textAlign: 'center' }}>
                    <SecondaryALink
                        className={'note'}
                        onClick={() => {
                            setAnimation('--slide-down')
                            waitForAnimation(() => props.onCancel?.())
                            history.push({
                                pathname: ROUTES.REGISTER,
                                search: QueryString.stringify({
                                    afterLoginURL: afterLoginURL
                                })
                            })
                        }}
                    >
                        还没有账号？快来注册
                    </SecondaryALink>
                </div>
            </FormStyle>
            <Placeholder />
            <WeixinLogin
                onClick={() => {
                    wxAuthRedirect('snsapi_userinfo', WEIXIN_AUTH_OPERATION.AUTH_AND_LOGIN, afterLoginURL)
                }}
            >
                <div className="icon" />
                <div className="text">微信一键登录</div>
            </WeixinLogin>
        </PageStyle>
    )
}

export default Login
