export enum ROUTES {
    ROOT = '/',
    RIP = '/RIP',

    HOME = '/home',
    APP_HOME = '/collegeHome', // app
    LIVE_MORE = '/liveMore',
    COLUMN_MORE = '/columnMore',
    TOP_COURSE_LIST = '/topCourseList',
    LABEL_COURSE_LIST = '/labelCourseList',
    LIVE_LIST_PM = '/liveListPm',
    SEARCH_COURSE = '/searchCourse',
    SECKILL_LIST = '/seckillList', // 秒杀更多列表

    REGISTER = '/register',
    AGREEMENT = '/agreement',
    PRIVACY = '/privacy',
    LOGIN = '/login',
    OAUTH_WEIXIN = '/oauth/weixin', // 微信授权结果处理
    RESET_PASSWORD = '/resetPassword',
    BIND_PHONE = '/bindPhone',

    /* 课程页面 */
    COLUMN_DETAIL = '/columnDetail', // app 专栏
    LIVE_DETAIL = '/courseDetail', // app 直播详情
    WATCH_LIVE = '/live', // app 直播互动
    VIDEO_DETAIL = '/videoDetail', // app 视频课
    FACE2FACE_DETAIL = '/faceDetail', // app 面授课
    COLLECTION_DETAIL = '/collectionDetail', // 课程合辑
    AUDIO_DETAIL = '/audioDetail', // 音频  // app

    ORDER_LIST = '/myOrderList', // 消费记录
    EVALUATE = '/evaluate', // 消费记录
    MY_COURSE_LIST = '/courseDuration', // 我的学习课程/已购课程 // app
    VOUCHER_DETAIL = '/myVoucherDetail', // 优惠券详情
    MY_VOUCHER_LIST = '/myVoucherList', // 我的优惠券列表
    MY_VOUCHER_HISTORY = '/myVoucherHistory', // 历史卡券

    VOUCHER_SHARE_DETAIL = '/voucherDetail', // 分享优惠券
    VOUCHER_CENTER = '/voucherCenter', // 领券中心 // app
    REDEEM = '/redeemCode', // 兑换 // app
    REDEEM_HISTORY = '/redeemHistory', // 兑换记录 // app
    TAG_LIST = '/tagList', // 标签配置列表（广东律协） // app

    /* app 支付用 */
    APP_PAY = '/pment',
    APP_PAY_RESULT = '/payResult',

    /* 微信支付 */
    WEIXIN_PAY = '/wPment',
    WEIXIN_PAY_RESULT = '/wPayResult',
    WEIXIN_PAY_SUCCESS = '/wPaySuccess',

    WEB_LIVE = '/weblive', // PC 看直播
    DOWNLOAD_APP = '/download', // 下载引导页
    SIXTH = '/6thAnniversary', // 六周年活动
    DOUBLE_ELEVENTH = '/11.11', // 双十一活动

    LAW_SOCIETY_SECTION = '/lawSocietySection', //律协专区 // app
    LAW_STUDY_LIST = '/lawStudyList', //律协在学 // app
    LAW_SOCIETY_COURSE = '/lawSocietyCourse', // 律协专区课程 // app
    DOWN_VOUCHER = '/downVoucher', // 下载学习凭证 // app

    DOUBLE_TWELVE = '/12.12', // 双十一活动
    MAY_DAY = '/5.1', // 双十一活动
    CREDENTIAL_GUIDE = '/credentialGuide', // 律师认证引导 - 领取课程
    HELP_CENTER = '/helpCenter', // 无讼律师APP帮助中心

    LOGOUT = '/logout', // 注销

    MY = '/my', // 我的,
    CHANGE_NAME = '/changeName',
    ACCOUNT_SETTING = '/accountSetting',
    CHANGE_PSD = '/changePsd',
    CHANGE_PHONE = '/changePhone',

    ANNUAL_REPORT = '/annualReport', // 年度报告
    ANNUAL_REPORT_RESULT = '/annualResult', // 年度报告

    //拼团
    GROUP_BUYING_LIST = '/groupBuyingList',
    GROUP_BUYING_DETAIL = '/groupBuyingDetail',

    ARTICLE_COLUMN = '/articleColumn', // app
    ARTICLE_COLUMN_DETAIL = '/articleColumnDetail', // app

    LAW_COURSE_SEARCH = '/lawCourseSearch', // 律协专区搜索课程

    ALL_SORT = '/allSortCourses',

    NOT_FOUND = '/404',

    // 蒋律基金开幕式
    FOUNDATION = '/foundation',
    FOUNDATION_TEST = '/foundationTest',
    FOUNDATION_WISHES = '/foundationWish',
    FOUNDATION_DONATION = '/foundationDonation',
    FOUNDATION_LIVE = '/foundationLive',
    FOUNDATION_STORY = '/foundationStory',
    FOUNDATION_LOTTERY = '/foundationLottery',
    FOUNDATION_MY_PRICE = '/foundationMyPrice',

    // 第三方合作课程精选
    PARTNER_COURSE = '/partnerCourse',
    PARTNER_COLUMN = '/partnerColumn',
    // 会员
    VIP_LANDING_PAGE = '/vipLandingPage',
    VIP_EQUITY_PAGE = '/vipEquityPage',
    VIP_ENTRY = '/vipEntry',
    INVOICE = '/invoice',
    INVOICING_LIST = '/invoicing-list',
    INVOICE_FORM = '/invoice-form',
    INVOICE_DETAIL = '/invoice-detail',
    INVOICE_RESULT = '/invoice-result',
    INVOICE_HISTORY = '/invoice-history',
    INVOICE_HISTORY_DETAIL = '/invoice-history-detail',
    INVOICE_CORRELATION_ORDERS = '/invoice-correlation-orders'
}
