import chatroomRM from '../components/IMPlatform/IMChatroom/reducers'
import commentReducer, { COMMENT_STORE_KEY } from '../store/commentReducer'
import webLiveReducer from '../components/WebLive/reducer/reducer'
import SearchFilters from '../reducers/SearchFilters'
import { combineReducers } from 'redux'

const rootReducer = combineReducers({
    ...chatroomRM,
    ...webLiveReducer,
    [COMMENT_STORE_KEY]: commentReducer,
    searchReducer: SearchFilters
})
export type Store = ReturnType<typeof rootReducer>
export default rootReducer
