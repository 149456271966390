import { createReducer } from '@reduxjs/toolkit'
import {
    appendCommentListAction,
    clearCommentAction,
    updateCommentAction,
    updateCommentListAction,
    updateCommentListRef,
    updateCommentLoading,
    updateCommentPositionAction,
    updateCourse,
    updateHistoryCommentListAction,
    updateMuteStatusAction,
    updateReplyingComment,
    updateUserInfo
} from '../actions/commentActions'
import { VirtuosoMethods } from 'react-virtuoso'

export const COMMENT_STORE_KEY = '__COMMENT_STORE__'

export enum COMMENT_TYPE {
    NORMAL,
    QUESTION
}
type CommentUser = {
    userId: string
    name: string
    photo: string
}
export interface Comment {
    from: CommentUser
    commentId: string
    parentComment: Comment
    parentCommentId: string
    comment: string | null
    imageUrl: string
    submitId: string
    submitTime: string
    submitTimeStamp: string
    revokeStatus: boolean
    main: boolean
    type?: COMMENT_TYPE
    index?: number // 列表中的序号
}
export type CommentStore = {
    loading: boolean
    isScrolling: boolean
    userScrollPosition: number // 列表最下方显示的消息 index
    commentListRef: VirtuosoMethods | null // 评论列表组件引用
    replyingComment: Comment | null // 非空则切换输入框为回复模式

    courseId?: string
    userId?: string // 当前登录用户
    token?: string // 当前登录用户
    mute?: boolean // 登录用户被禁言状态

    commentList: Comment[]
}
const initialState: CommentStore = {
    loading: false,
    isScrolling: false,
    userScrollPosition: 0,
    replyingComment: null,
    commentListRef: null,
    commentList: []
}

export default createReducer(initialState, builder =>
    builder
        .addCase(updateCourse, (state, action) => {
            state.courseId = action.payload
            return
        })
        .addCase(updateUserInfo, (state, action) => {
            state.userId = action.payload.userId
            state.token = action.payload.token
            return
        })
        .addCase(updateCommentAction, (state, action) => {
            const index = state.commentList.findIndex(c => c.commentId === action.payload.commentId)
            state.commentList[index] = {
                ...state.commentList[index],
                ...action.payload
            }
            return
        })
        .addCase(updateCommentListAction, (state, action) => {
            state.commentList = action.payload.map((c, index) => ({ ...c, index }))
            return
        })
        .addCase(appendCommentListAction, (state, action) => {
            state.commentList = state.commentList.concat(action.payload).map((c, index) => ({ ...c, index }))
            return
        })
        .addCase(updateHistoryCommentListAction, (state, action) => {
            state.commentList = action.payload.concat(state.commentList).map((c, index) => ({ ...c, index }))
            return
        })
        .addCase(clearCommentAction, state => {
            state.commentList = []
            return
        })
        .addCase(updateCommentPositionAction, (state, action) => {
            state.userScrollPosition = action.payload
            console.log('position', action.payload)
            return
        })
        .addCase(updateCommentLoading, (state, action) => {
            state.loading = action.payload
            return
        })
        .addCase(updateReplyingComment, (state, action) => {
            state.replyingComment = action.payload
            return
        })
        .addCase(updateCommentListRef, (state, action) => {
            state.commentListRef = action.payload
            return
        })
        .addCase(updateMuteStatusAction, (state, action) => {
            state.mute = action.payload
            return
        })
)
