// const DESIGN_DPR = 2
const DESIGN_WIDTH = 750
const REM_SLICE = 100 // 100 等分

/* 只返回整数倍率 */
function calcDPR(): number {
    // const base = DESIGN_WIDTH / DESIGN_DPR
    // const width = window.innerWidth * (window.devicePixelRatio || 1)
    // const factor = width / base

    /* 非缩放 */
    return 1
    /* viewport 高清缩放 */
    // if (factor > 1) {
    //     return Math.round(factor)
    // } else {
    //     return 1
    // }
}

const dpr = calcDPR()
export function initializeRemLayout(): void {
    function setViewport(): void {
        if (document) {
            const viewport = document.createElement('meta')
            const scale = 1 / dpr
            viewport.setAttribute('name', 'viewport')
            viewport.setAttribute(
                'content',
                `initial-scale=${scale}, maximum-scale=${scale}, minimum-scale=${scale}, user-scalable=no`
            )

            const old = document.head.children.namedItem('viewport')
            if (old) {
                document.head.removeChild(old)
            }
            document.head.appendChild(viewport)
        }
    }
    function setRootFontsize(): void {
        /* REM 尺寸根字体大小 */
        let zoom
        if (window.innerWidth / DESIGN_WIDTH < 0.5) {
            zoom = 0.5 // 防止线宽小于 0.5
        } else {
            zoom = window.innerWidth / DESIGN_WIDTH
        }
        document.documentElement.style.fontSize = dpr * zoom * REM_SLICE + 'px'
    }

    window.addEventListener('DOMContentLoaded', setViewport)
    window.addEventListener('DOMContentLoaded', setRootFontsize)
    window.addEventListener('resize', setRootFontsize)
    window.addEventListener('orientationchange', setRootFontsize)
}

/**
 * DPR 相对尺度计算
 */
// export function relativeLength(px: number): string {
//   return (dpr * px) / DESIGN_DPR + 'px'
// }

/**
 * REM 相对尺度计算
 */
export function relativeLength(px: number): string {
    const rem = px / REM_SLICE
    return rem + 'rem'
}

/**
 * VW 相对尺度计算
 */
// export function relativeLength(px: number): string {
//   const percentage = px / DESIGN_WIDTH
//   return `${percentage * 100}vw`
// }
