import React, { PropsWithChildren, useCallback, useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import { useHistory } from 'react-router'
import styled, { createGlobalStyle } from 'styled-components'
import QueryString from 'qs'
import Login, { LoginProps } from '../Login'
import Axios, { AxiosResponse } from 'axios'
import { WSResponseType } from '../../constants/types'
import { Toast } from 'antd-mobile'
import { LOGOUT } from '../../constants/api'
import { ROUTES } from '../../constants/routes'
import URI from 'urijs'

const GlobalOverlayStyle = createGlobalStyle``
const PageOverlay = styled.div`
    position: fixed;
    left: 0;
    top: 0;

    width: 100vw;
    height: 100vh;
    background: transparent;

    z-index: 9999;

    * {
        box-sizing: border-box;
    }
`

/**
 * 取出 session
 */
export function getSession(): Session | undefined {
    const session = localStorage?.getItem(STORAGE_TOKEN_KEY)
    if (session) {
        try {
            return JSON.parse(session)
        } catch (e) {
            console.error(e)
            return undefined
        }
    } else {
        return undefined
    }
}

/**
 * 存储当前 session
 */
export function saveSession(session: Session): void {
    localStorage?.setItem(STORAGE_TOKEN_KEY, JSON.stringify(session))
}

function removeSession(): void {
    localStorage?.removeItem(STORAGE_TOKEN_KEY)
}

export function logout(): void {
    Axios.get<WSResponseType>(LOGOUT)
        .then(res => res.data)
        .then(({ code, message }) => {
            if (code === 0) {
                window.gio('clearUserId')
                removeSession()
                window.location.href = ROUTES.HOME
            } else {
                Toast.fail(message)
            }
        })
}

const STORAGE_TOKEN_KEY = 'ws_college_session'
type SessionParams = {
    token?: string
}
type Session = {
    token: string
    userId: string
    unionId?: null | string
    openId?: string
    name: string
    phone: string
    email?: null | string
    photo: string
    goslingUserId?: string
}
export const SessionContext = React.createContext<Session | undefined>(undefined)

/**
 * 全局 login 方法参数
 */
type LoginConfig = { afterLogin?: () => void; afterCancel?: () => void; afterLoginURL?: string }
export type GlobalLoginMethod = (config?: LoginConfig) => Promise<void>
export const SessionValidatorContext = React.createContext<{
    requireLogin: GlobalLoginMethod
}>({ requireLogin: () => Promise.resolve() })

/**
 * 登录检查器
 * 将登录后的 token 存入 storage，
 * 提供全局 context 查询 token，
 * 支持以下 session 形式
 * - query 参数 [token]
 * - storage 存储 [token]
 * - ...
 */
export function SessionProvider(props: PropsWithChildren<{}>): JSX.Element {
    const history = useHistory()
    const [session, setSession] = useState<Session>()
    const [showLogin, setShowLogin] = useState(false)

    const loginConfig = useRef<LoginProps>({})
    /**
     * 判断登录状况
     * 已登录直接通过，
     * 未登录弹出登录界面，直至登录结束
     */
    const requireLogin = useCallback<GlobalLoginMethod>(function requireLogin(config) {
        return new Promise<void>((resolve, reject) => {
            let url = window.location.href
            if (config?.afterLoginURL) {
                const uri = new URI(config.afterLoginURL)
                url = uri.toString()
            }

            const session = getSession()
            if (session?.token) {
                // afterLogin?.()
                resolve()
            } else {
                loginConfig.current = {
                    transition: true,
                    afterLoginURL: url,
                    onLogin: () => {
                        // close
                        setShowLogin(false)
                        // afterLogin?.();
                        resolve()
                    },
                    onCancel: () => {
                        // close
                        setShowLogin(false)
                        // afterCancel?.();
                        reject()
                    }
                }
                setShowLogin(true)
            }
        })
    }, [])

    const params: SessionParams = QueryString.parse(history.location.search.substring(1))
    /**
     * 处理身份验证
     * - 若以 query 形式访问页面，使用 query 的 token，保持原有逻辑不处理 session
     * - 若没有 query，从 storage 存取 session，未登录时要求登录，
     *   登录逻辑仅操作 storage，不通过 query 操作 token
     */
    useEffect(() => {
        if (params.token) {
            // 先取 query，以 token 身份访问，不处理
        } else {
            const session = getSession()
            if (session) {
                // 再取 storage
                setSession(session)
            } else {
                // 未登录
            }
        }

        /* 处理登录过期 */
        Axios.interceptors.response.use(
            async function (response: AxiosResponse<WSResponseType>) {
                // Any status code that lie within the range of 2xx cause this function to trigger
                // Do something with response data
                const { data: res } = response
                if (res.code === 2000) {
                    /* 登录已过期 */
                    removeSession() // 同步清除本地 session
                    Toast.info('登录状态已失效，请重新登录')
                    await requireLogin()
                    return response
                } else {
                    return response
                }
            },
            function (error) {
                // Any status codes that falls outside the range of 2xx cause this function to trigger
                // Do something with response error
                console.error(error)
                return Promise.reject(error)
            }
        )
    }, [params.token, requireLogin])

    return (
        <SessionContext.Provider value={session}>
            <SessionValidatorContext.Provider value={{ requireLogin }}>
                {ReactDOM.createPortal(
                    showLogin ? (
                        <PageOverlay>
                            <GlobalOverlayStyle />
                            <Login {...loginConfig.current} />
                        </PageOverlay>
                    ) : null,
                    document.body
                )}
                {props.children}
            </SessionValidatorContext.Provider>
        </SessionContext.Provider>
    )
}
