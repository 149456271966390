import { recordFilters } from '../actions/SearchFilters'
import { createReducer } from '@reduxjs/toolkit'

const initalState = { recordConditions: {} }
export default createReducer(initalState, builder =>
    builder.addCase(recordFilters, (state, action) => {
        state.recordConditions = action.payload
        return
    })
)
export { initalState as SearchFilters }
