import {
    addMessageListTransformer,
    appendMessageListAction,
    appendRawMessagesAction,
    enterChatroom,
    exitChatroom,
    modifyMessageAction,
    prependMessageListAction,
    prependRawMessagesAction,
    removeMessageAction,
    setListRef,
    updateChatroom,
    updateChatroomInstance,
    updateChatroomMap,
    updateChatroomMemberList,
    updateDynamicMessageList,
    updateExternalMessageFilter,
    updateLastReadPosition,
    updateListLoading,
    updateLoading,
    updateLoginInfo,
    updateMyInfo,
    updateNIM,
    updateQuestionMode,
    updateReplyingMessage,
    updateScrollPosition,
    updateSpecializedMessageFilter,
    updateTotalVisibleMessageCount,
    updateVisibleMessageCount
} from '../actions'
import { createReducer } from '@reduxjs/toolkit'
import {
    ChatroomInfo,
    ChatroomInstance,
    ChatroomObject,
    Credentials,
    Member,
    Message,
    MessageFilter,
    MessageTransformer,
    UserInfo
} from '../types'
import { VirtuosoMethods } from 'react-virtuoso'

type InnerStore = Readonly<{
  loading: boolean
  listLoading: boolean
  userScrollPosition: number // 用户滚动标记: null 为滚动到底部, number 为消息 index
  lastReadPosition: number // 已读消息位置
  listRef?: VirtuosoMethods // 消息列表引用，用于处理滚动
  visibleMessageCount: number // 已加载的可见消息数
  totalVisibleMessageCount: number // 总消息数（含未加载的历史记录）
  isQuestion: boolean // 提问消息开关（和回复互斥）
  replyingMessage: Message | null // 非空则切换输入框为回复模式

  messageProcessPipeline: MessageTransformer[] // 消息处理管道
  messageList: Message[] // 数据处理后的消息
  dynamicMessageList: Message[] // 动态展示的消息

  specializedFilter: MessageFilter // 专用过滤器，用于不同 UI 自定义消息过滤
  externalFilter: MessageFilter // 外部过滤器，用于最终使用者自定义消息过滤

  NIM: any // NIM instance
  user: Credentials
  chatroomMap: {
    [key: string]: ChatroomObject
  }
  chatroomInsts: { [key: string]: ChatroomInstance }
  chatroomId?: number
  chatroom?: ChatroomInstance
  chatroomMsgs: Message[] // 未修改的原始消息
  chatroomInfo?: ChatroomInfo
  chatroomMembers: Member[]
  myInfo?: UserInfo
  userList: UserInfo[]
}>

const initialState: InnerStore = {
  loading: true,
  listLoading: true,
  userScrollPosition: 0,
  lastReadPosition: 0,
  listRef: undefined,
  visibleMessageCount: 0,
  totalVisibleMessageCount: 0,
  isQuestion: false,
  replyingMessage: null,

  messageProcessPipeline: [],
  messageList: [],
  dynamicMessageList: [],
  specializedFilter: () => true,
  externalFilter: () => true,

  NIM: undefined,
  user: {},
  chatroomMap: {},
  chatroomInsts: {},
  chatroomMembers: [],
  chatroomMsgs: [],
  // chatroomMsgs: Array(100).fill(null).map(() => messageMock()),
  userList: []
}

export const removeReducer: (timestamp: string) => MessageTransformer = (timestamp: string) =>
  function(messageList) {
    const i = messageList.findIndex(m => m.time.toString() === timestamp)
    if (i >= 0) {
      const copy = [...messageList]
      copy.splice(i, 1)
      return copy
    } else {
      return messageList
    }
  }

export const modifyReducer: (message: Partial<Message> & { time: number }) => MessageTransformer =
  (message) =>
    function(messageList) {
      const i = messageList.findIndex(m => m.time === message.time)
      if (i >= 0) {
        messageList[i] = {
          ...messageList[i],
          ...message,
          attach: {
            ...messageList[i].attach,
            ...message.attach
          }
        }
      }
      return messageList
    }

const reducer = createReducer(initialState, builder => builder
  .addCase(updateNIM, (state, action) => {
    state.NIM = action.payload
    return
  })
  .addCase(updateLoading, (state, action) => {
    state.loading = action.payload
    return
  })
  .addCase(updateListLoading, (state, action) => {
    state.listLoading = action.payload
    return
  })
  .addCase(updateLoginInfo, (state, action) => {
    state.user.accid = action.payload.accid
    state.user.token = action.payload.token
    return
  })
  .addCase(updateChatroomInstance, (state, action) => {
    state.chatroomInsts[action.payload.id] = action.payload.chatroom
    return
  })
  .addCase(updateChatroomMap, (state, action) => {
    state.chatroomMap = {
      ...state.chatroomMap,
      ...action.payload
    }
    return
  })
  .addCase(updateChatroom, (state, action) => {
    const newInfo = {
      ...state.chatroomInfo,
      ...action.payload
    }
    state.chatroomInfo = newInfo
    return
  })
  .addCase(enterChatroom, (state, action) => {
    state.chatroomId = action.payload.chatroomId
    state.chatroom = action.payload.chatroom
    state.chatroomMsgs = []
    state.messageList = []
    state.dynamicMessageList = []
    state.chatroomMembers = []
    state.messageProcessPipeline = []
    return
  })
  .addCase(exitChatroom, (state) => {
    // delete state.chatroomInsts[state.chatroomId ?? '']
    // delete state.chatroomMap[state.chatroomId ?? '']
    // state.NIM = undefined
    // state.chatroom = undefined
    // state.chatroomId = undefined
    state.chatroomMsgs = []
    state.messageList = []
    state.dynamicMessageList = []
    state.chatroomInfo = undefined
    state.chatroomMembers = []
    state.messageProcessPipeline = []
    return
  })
  .addCase(appendRawMessagesAction, (state, action) => {
    /* 云信接收的原始消息，不做任何处理 */
    state.chatroomMsgs = state.chatroomMsgs
      .concat(action.payload)
      .map((c, index) => ({ ...c, index }))
    return
  })
  .addCase(prependRawMessagesAction, (state, action) => {
    /* 云信接收的原始消息，不做任何处理 */
    state.chatroomMsgs = action.payload
      .concat(state.chatroomMsgs)
      .map((c, index) => ({ ...c, index }))
    return
  })
  .addCase(appendMessageListAction, (state, action) => {
    /* 处理后的消息数据 */
    state.messageList = state.messageList.concat(action.payload)
    /* 对历史数据执行变换序列 */
    const processed = state.messageProcessPipeline.reduce((acc, reducer) => {
      return reducer(acc)
    }, state.messageList)
    state.messageList = processed
    return
  })
  .addCase(prependMessageListAction, (state, action) => {
    /* 处理后的消息数据 */
    state.messageList = action.payload.concat(state.messageList)
    /* 对历史数据执行变换序列 */
    const processed = state.messageProcessPipeline.reduce((acc, reducer) => {
      return reducer(acc)
    }, state.messageList)
    state.messageList = processed
    return
  })
  .addCase(modifyMessageAction, (state, action) => {
    const message = action.payload
    state.messageList = modifyReducer(message)(state.messageList)
    return
  })
  .addCase(removeMessageAction, (state, action) => {
    const timestamp = action.payload
    state.messageList = removeReducer(timestamp)(state.messageList)
    return
  })
  .addCase(updateDynamicMessageList, (state, action) => {
    /* 经过动态过滤展示的消息，直接传入 VList */
    state.dynamicMessageList = state.messageList.filter(state.externalFilter)
    return
  })
  .addCase(addMessageListTransformer, (state, action) => {
    /* 经过动态过滤展示的消息，直接传入 VList */
    state.messageProcessPipeline.push(action.payload)
    return
  })
  .addCase(updateChatroomMemberList, (state, action) => {
    const { type, members = [] } = action.payload
    if (type === 'destroy') {
      state.chatroomMembers = []
    } else if (type === 'put') {
      state.chatroomMembers = state.chatroomMembers.concat(members.filter(member => member.online))
    }
    return
  })
  .addCase(updateMyInfo, (state, action) => {
    state.myInfo = {
      ...state.myInfo,
      ...action.payload
    }
    return
  })
  // .addCase(updateUserInfo, (state, action) => {
  //   const userList = state.userList
  //   action.payload.forEach(user => {
  //     const account = user.account
  //     if (account) {
  //       userList[account] = Object.assign(userList[account], user)
  //     }
  //   })
  //   state.userList = Object.assign(state.userList, userList)
  //   return
  // })
  .addCase(updateScrollPosition, (state, action) => {
    console.log('pos', action.payload)
    state.userScrollPosition = action.payload
    return
  })
  .addCase(updateReplyingMessage, (state, action) => {
    state.replyingMessage = action.payload
    return
  })
  .addCase(updateQuestionMode, (state, action) => {
    state.isQuestion = action.payload
    return
  })
  .addCase(setListRef, (state, action) => {
    state.listRef = action.payload
    return
  })
  .addCase(updateLastReadPosition, (state, action) => {
    console.log('read', action.payload)
    state.lastReadPosition = action.payload
    return
  })
  .addCase(updateVisibleMessageCount, (state, action) => {
    state.visibleMessageCount = action.payload
    return
  })
  .addCase(updateTotalVisibleMessageCount, (state, action) => {
    state.totalVisibleMessageCount = action.payload
    return
  })
  .addCase(updateSpecializedMessageFilter, (state, action) => {
    state.specializedFilter = action.payload
    return
  })
  .addCase(updateExternalMessageFilter, (state, action) => {
    state.externalFilter = action.payload
    return
  })
)

export const OUTER_STORE_KEY = 'IMChatroom'
export type Store = {
  [OUTER_STORE_KEY]: InnerStore
}
export default {
  [OUTER_STORE_KEY]: reducer
}
