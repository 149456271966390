import { createAction } from '@reduxjs/toolkit'
import { ThunkAction } from 'redux-thunk'
import { Comment, COMMENT_STORE_KEY } from '../store/commentReducer'
import { Action } from 'redux'
import axios from 'axios'
import { GET_COMMENT_HISTORY_LIST, GET_MUTE_STATUS, MUTE_USER, REVOKE_COMMENT, SEND_COMMENT } from '../constants/api'
import { Store } from '../store/reducers'
import { VirtuosoMethods } from 'react-virtuoso'
import { Toast } from 'antd-mobile'

const ACTION_PREFIX = '_COMMENT_'
const UPDATE_COURSE = ACTION_PREFIX + 'UPDATE_COURSE'
const UPDATE_USER_INFO = ACTION_PREFIX + 'UPDATE_USER_INFO'
const UPDATE_COMMENT = ACTION_PREFIX + 'UPDATE_COMMENT'
const UPDATE_COMMENT_LIST = ACTION_PREFIX + 'UPDATE_COMMENT_LIST'
const APPEND_COMMENT_LIST = ACTION_PREFIX + 'APPEND_COMMENT'
const UPDATE_HISTORY_COMMENT_LIST = ACTION_PREFIX + 'UPDATE_HISTORY_COMMENT_LIST'
const CLEAR_COMMENT_LIST = ACTION_PREFIX + 'CLEAR_COMMENT_LIST'
const UPDATE_COMMENT_POSITION = ACTION_PREFIX + 'UPDATE_COMMENT_POSITION'
const UPDATE_COMMENT_LOADING = ACTION_PREFIX + 'UPDATE_COMMENT_LOADING'
const UPDATE_REPLYING_COMMENT = 'UPDATE_REPLYING_COMMENT'
const UPDATE_COMMENT_LIST_REF = 'UPDATE_COMMENT_LIST_REF'
const UPDATE_IS_SCROLLING = 'UPDATE_IS_SCROLLING'
const UPDATE_MUTE_STATUS = 'UPDATE_MUTE_STATUS'

export const updateCourse = createAction<string>(UPDATE_COURSE)
export const updateUserInfo = createAction<{ userId: string; token: string }>(UPDATE_USER_INFO)
export const updateCommentLoading = createAction<boolean>(UPDATE_COMMENT_LOADING)
export const updateCommentPositionAction = createAction<number>(UPDATE_COMMENT_POSITION)
export const updateCommentListAction = createAction<Comment[]>(UPDATE_COMMENT_LIST)
export const updateCommentAction = createAction<Pick<Comment, 'commentId'> & Partial<Comment>>(UPDATE_COMMENT)
export const appendCommentListAction = createAction<Comment[]>(APPEND_COMMENT_LIST)
export const updateHistoryCommentListAction = createAction<Comment[]>(UPDATE_HISTORY_COMMENT_LIST)
export const clearCommentAction = createAction(CLEAR_COMMENT_LIST)
export const updateReplyingComment = createAction<Comment | null>(UPDATE_REPLYING_COMMENT)
export const updateCommentListRef = createAction<VirtuosoMethods | null>(UPDATE_COMMENT_LIST_REF)
export const updateIsScrolling = createAction<boolean>(UPDATE_IS_SCROLLING)
export const updateMuteStatusAction = createAction<boolean>(UPDATE_MUTE_STATUS)

type ResponseType<T = any> = {
    code: number
    message: string
    data: T
}
const getLatestCommentList =
    (userList?: string[]): ThunkAction<void, Store, unknown, Action<string>> =>
    async (dispatch, getState): Promise<void> => {
        const courseId = getState()[COMMENT_STORE_KEY].courseId
        const curUserId = getState()[COMMENT_STORE_KEY].userId
        const token = getState()[COMMENT_STORE_KEY].token

        if (courseId) {
            try {
                const {
                    data: { code, message, data = [] }
                } = await axios.post<ResponseType<Comment[]>>(
                    GET_COMMENT_HISTORY_LIST,
                    {
                        courseId,
                        curUserId,
                        pageSize: 50,
                        // lastSubmitTimestamp: Date.now(),
                        lectureOrAssistants: userList || []
                    },
                    { headers: { 'X-Auth-Token': token } }
                )

                if (code === 0) {
                    dispatch(updateCommentListAction(data))
                    dispatch(scrollToCommentPosition(data.length))
                } else {
                    console.error(message)
                }
            } catch (e) {
                console.error('网络连接失败')
            }
        } else {
            console.error('未关联课程信息')
        }
    }

export const getHistoryCommentList =
    (userList?: string[]): ThunkAction<void, Store, unknown, Action<string>> =>
    async (dispatch, getState): Promise<void> => {
        const courseId = getState()[COMMENT_STORE_KEY].courseId
        const curUserId = getState()[COMMENT_STORE_KEY].userId
        const token = getState()[COMMENT_STORE_KEY].token
        const loading = getState()[COMMENT_STORE_KEY].loading
        const firstComment = getState()[COMMENT_STORE_KEY].commentList[0]
        const listSize = getState()[COMMENT_STORE_KEY].commentList.length
        const listRef = getState()[COMMENT_STORE_KEY].commentListRef

        if (!loading && courseId) {
            try {
                dispatch(updateCommentLoading(true))
                const {
                    data: { code, message, data = [] }
                } = await axios.post<ResponseType<Comment[]>>(
                    GET_COMMENT_HISTORY_LIST,
                    {
                        courseId,
                        curUserId,
                        pageSize: 50,
                        lastSubmitTimestamp: firstComment ? firstComment.submitTimeStamp : undefined,
                        lectureOrAssistants: userList || []
                    },
                    {
                        headers: { 'X-Auth-Token': token }
                    }
                )

                if (code === 0) {
                    dispatch(updateHistoryCommentListAction(data))
                    dispatch(updateCommentLoading(false))
                    console.log('history', listSize, data)
                    listRef?.adjustForPrependedItems(data.length)
                    if (data.length > 0) {
                        dispatch(scrollToCommentPosition(data.length))
                    }
                } else {
                    console.error(message)
                }
            } catch (e) {
                console.error('网络连接失败')
            }
        } else {
            console.error('未关联课程信息')
        }
    }

// 发送回复时 to 为消息 id
// type = 1, 提问类型
export const sendText =
    (
        content: string,
        type: number,
        to?: string,
        done?: (e: Error | null, comment?: Comment) => void
    ): ThunkAction<void, Store, unknown, Action<string>> =>
    async (dispatch, getState): Promise<void> => {
        const courseId = getState()[COMMENT_STORE_KEY].courseId
        const userId = getState()[COMMENT_STORE_KEY].userId
        const token = getState()[COMMENT_STORE_KEY].token

        if (courseId && userId) {
            try {
                const params = new FormData()
                params.append('courseId', courseId)
                params.append('comment', content)
                params.append('type', type.toString())
                if (to) {
                    params.append('to', to)
                }

                const {
                    data: { code, message, data }
                } = await axios.post<ResponseType<Comment>>(SEND_COMMENT, params, {
                    headers: { 'X-Auth-Token': token }
                })

                if (code === 0) {
                    dispatch(getLatestCommentList())
                    if (done) {
                        done(null, data)
                    }
                } else {
                    console.error(message)
                    dispatch(getMuteState())
                    if (done) {
                        done(new Error(message))
                    }
                }
            } catch (e) {
                console.error('网络连接失败')
            }
        } else {
            console.error('未关联课程信息')
        }
    }

// 发送回复时 to 为消息 id
// type = 1, 提问类型
export const sendImage =
    (
        image: File,
        type: number,
        to?: string,
        done?: (e: Error | null, comment?: Comment) => void
    ): ThunkAction<void, Store, unknown, Action<string>> =>
    async (dispatch, getState): Promise<void> => {
        const courseId = getState()[COMMENT_STORE_KEY].courseId
        const userId = getState()[COMMENT_STORE_KEY].userId
        const token = getState()[COMMENT_STORE_KEY].token

        if (courseId && userId) {
            try {
                const params = new FormData()
                params.append('courseId', courseId)
                params.append('imageFile', image)
                params.append('type', type.toString())
                if (to) {
                    params.append('to', to)
                }

                const {
                    data: { code, message }
                } = await axios.post<ResponseType<Comment>>(SEND_COMMENT, params, {
                    headers: { 'X-Auth-Token': token }
                })

                if (code === 0) {
                    dispatch(getLatestCommentList())
                    if (done) {
                        done(null)
                    }
                } else {
                    console.error(message)
                    dispatch(getMuteState())
                    if (done) {
                        done(new Error(message))
                    }
                }
            } catch (e) {
                console.error('网络连接失败')
            }
        } else {
            console.error('未关联课程信息')
        }
    }

export const revoke =
    (commentId: string, main: boolean): ThunkAction<void, Store, unknown, Action<string>> =>
    async (dispatch, getState): Promise<void> => {
        const curUserId = getState()[COMMENT_STORE_KEY].userId
        const token = getState()[COMMENT_STORE_KEY].token
        try {
            const {
                data: { code, message }
            } = await axios.post<ResponseType<number>>(
                REVOKE_COMMENT,
                {
                    commentId,
                    curUserId,
                    main,
                    revokeStatus: true
                },
                {
                    headers: { 'X-Auth-Token': token }
                }
            )

            if (code === 0) {
                dispatch(getLatestCommentList())
            } else {
                Toast.fail(message)
            }
        } catch (e) {
            Toast.fail('网络连接失败')
            console.error('网络连接失败')
        }
    }

export const scrollToCommentPosition =
    (index?: number): ThunkAction<void, Store, unknown, Action<string>> =>
    (dispatch, getState) => {
        const listRef = getState()[COMMENT_STORE_KEY].commentListRef
        const listSize = getState()[COMMENT_STORE_KEY].commentList.length
        if (listRef) {
            const to = index ? index : listSize
            dispatch(updateCommentPositionAction(to))
            listRef.scrollToIndex(to)
        }
    }

/**
 * @param userId 被禁言用户
 * @param commentId
 * @param state
 */
export const mute =
    (userId: string, commentId: string, state: boolean): ThunkAction<void, Store, unknown, Action<string>> =>
    async (dispatch, getState): Promise<void> => {
        const courseId = getState()[COMMENT_STORE_KEY].courseId
        const token = getState()[COMMENT_STORE_KEY].token
        try {
            const {
                data: { code, message }
            } = await axios.post<ResponseType<boolean>>(
                MUTE_USER,
                {
                    courseId,
                    commentId,
                    target: userId,
                    mute: state
                },
                {
                    headers: { 'X-Auth-Token': token }
                }
            )

            if (code === 0) {
                dispatch(getLatestCommentList())
            } else {
                Toast.fail(message)
            }
        } catch (e) {
            Toast.fail('网络连接失败')
            console.error('网络连接失败')
        }
    }

export const getMuteState =
    (): ThunkAction<void, Store, unknown, Action<string>> =>
    async (dispatch, getState): Promise<void> => {
        const courseId = getState()[COMMENT_STORE_KEY].courseId
        const userId = getState()[COMMENT_STORE_KEY].userId
        const token = getState()[COMMENT_STORE_KEY].token
        try {
            const {
                data: { code, message, data }
            } = await axios.get<ResponseType<boolean>>(GET_MUTE_STATUS, {
                headers: { 'X-Auth-Token': token },
                params: { courseId, userId }
            })

            if (code === 0) {
                dispatch(updateMuteStatusAction(data))
            } else {
                Toast.fail(message)
            }
        } catch (e) {
            Toast.fail('网络连接失败')
            console.error('网络连接失败')
        }
    }
