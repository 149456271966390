import { Location } from 'history'
import { useHistory } from 'react-router'
import { useEffect } from 'react'
import { GIO_PAGE_VARS } from '../constants/pages'
import { useLocation } from 'react-router-dom'

export function useOnHistoryChange(updateFromType: (location: Location) => void): void {
    const routerLocation = useLocation()
    const history = useHistory()

    useEffect(() => {
        updateFromType(history.location)
        const unregister = history.listen(location => {
            updateFromType(location)
        })
        return () => unregister()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        // GreenIO页面级变量埋点
        console.log(routerLocation)
        GIO_PAGE_VARS.map(item => {
            if (item.path === routerLocation.pathname) {
                console.log(item.params)
                window.gio('page.set', { ...item.params })
            }
        })
    }, [routerLocation.pathname])
}
