import React from 'react'
import styled from 'styled-components'
import { LoadingOutlined } from '@ant-design/icons'
import { relativeLength } from '../utils/layout'

const LoadingContainer = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .anticon {
        margin-bottom: ${relativeLength(20)};
        font-size: ${relativeLength(60)};
        color: #4187f2;
    }
`
export default function Loading() {
    return (
        <LoadingContainer>
            <LoadingOutlined spin />
            {/*<img src={loading} alt={'loading'} />*/}
        </LoadingContainer>
    )
}
