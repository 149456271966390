import { ColumnDetailType } from '../components/ColumnDetail'
import { LiveCourseDetailType } from '../components/LiveCourseDetail'
import { VideoCourseDetailType } from '../components/VideoDetail'
import { FacialCourseDetailType } from '../components/FaceDetail'
import { AudioCourseDetailType } from '../components/AudioDetail'

// eslint-disable-next-line
export type WSResponseType<T = any> = {
    code: number
    message: string
    data: T
}

// eslint-disable-next-line
export type AppResponseType<T = any> = {
    result: {
        code: number
        message: string
    }
    data: T
}

export enum COURSE_TYPE {
    // 0 专栏,1  面授 2 音频 3 视频 4 直播
    COLUMN = 0,
    FACIAL = 1,
    AUDIO = 2,
    VIDEO = 3,
    LIVE = 4,
    COLLECTION = 5
}

export enum REQUIRED_TYPE {
    TRUE = 1,
    FALSE = 0
}

export enum LEARN_TYPE {
    NOT_LEARN = 0,
    LEARNING = 1,
    FINISHED = 2
}

export enum APP_MESSAGE_TYPE {
    STATUS = 0, // 课程状态消息
    END = 1, // 课程结束消息
    SIGN_IN = 4, // 课程签到
    RECOMMEND = 5 // 推荐商品
}

export enum LIST_TYPE {
    COLUMN = 10,
    FACE = 11,
    AUDIO = 12,
    VIDEO = 13,
    LIVE = 14,
    LABELS = 15,
    SINGLE_COURSE = 16,
    VOUCHER_CENTER = 22,
    COUPON_DETAIL = 23,
    ARTICLE_DETAIL = 27,
    INNER_SITE = 24,
    OUTER_SITE = 21
}

/**
 * 上架设置, 0 立即上架, 1暂不上架, 2 定时上架 3已下架
 */
export enum SALES_CONFIG {
    ON_SALE,
    BEFORE_SALE,
    TIMER,
    OFF_SALE
}

/**
 * 上架状态
 */
export enum SALE_STATUS {
    PENDING = 1, // onSaleTypeCode 上架状态: "待上架" ,2: "已上架", 3: "已下架
    ON_SALE = 2,
    OFF_SALE = 3
}

/**
 * 售卖类型
 */
export enum SALE_TYPE {
    BUNDLE, // 无法单独售卖
    INDIVIDUAL // 可以单独售卖
}

/**
 * 价格类型
 */
export enum PRICE_TYPE {
    PAID, // 收费
    FREE, // 免费
    TRIAL, // 试用
    BUNDLED // 非单独售卖 todo: refactor
}

/**
 * 课程价格数据格式
 */
export type PriceType = {
    priceId: string
    purchaseType: unknown // 价格渠道：'0 免费 1 app 2 微站',
    normalPrice: number // 价格（分）
    verifiedPrice: unknown // 认证后价格
}

/**
 * 直播状态
 */
export enum LIVE_STATE {
    BEFORE_START = 0, // 未开始
    LIVE = 1, // 1 直播中
    PLAYBACK = 2 // 已结束 / 回看
}

export enum LIVE_BACK_TYPE {
    OPEN_PLAYBACK,
    CLOSE_PLAYBACK
}

/**
 * 页面环境 / 打开来源
 */
export enum FROM_TYPE {
    WEB_APP = 'webapp',
    APP = 'app',
    SHARE = 'share'
}

/**
 * 评论开关
 */
export enum COMMENT_STATE {
    OFF,
    ON
}

export type UnionCourseType =
    | ColumnDetailType
    | LiveCourseDetailType
    | VideoCourseDetailType
    | FacialCourseDetailType
    | AudioCourseDetailType

export enum EXCEPTION_TYPE {
    NORMAL,
    OFF_SALE,
    DELETED
}

/**
 * 营销渠道:1秒杀、2直播带货
 */
export enum MARKETING_CHANNEL {
    SECKILL = 1,
    LIVE_SALE,
    GROUP_BUYING
}

/** 秒杀活动状态: 0未开始；1进行中*/
export enum ACTIVITY_STATUS {
    NOT_STARTED,
    IN_PROGRESS,
    EXPIRED // 未使用
}
/** 活动类型: 1秒杀；2拼团*/
export enum ACTIVITY_TYPE {
    SECKILL = 1,
    GROUP_BUYING // 未使用
}
