/**
 * action类型
 * */

export const CHANGE_COURSEID = 'CHANGE_COURSEID'
export const CHANGE_COURSE_TYPE = 'CHANGE_COURSE_TYPE'
export const CHANGE_PAY_QRCODE = 'CHANGE_PAY_QRCODE'

/**
 * action创建函数
 *
 */
export function saveCourseId(id) {
    return { type: CHANGE_COURSEID, id }
}

export function saveCourseType(value) {
    return { type: CHANGE_COURSE_TYPE, value }
}

export function changeQrcodeStatus(bool) {
    return { type: CHANGE_PAY_QRCODE, status: bool }
}
