import React, { lazy, Suspense } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import App from '../App'
import Loading from './Loading'
import { ROUTES } from '../constants/routes'

// 错误处理页面
const ErrorBoundary = lazy(() => import('../components/ErrorBoundary/ErrorBoundary'))
// 微站注册
const Register = lazy(() => import('../components/Login/Register'))
const Agreement = lazy(() => import('../components/Login/Agreement'))
// const Privacy = lazy(() => import('../components/Login/Privacy'))
// 微站登录
const Login = lazy(() => import('../components/Login'))
const OAuth = lazy(() => import('../components/Login/WeixinAuth'))
const ResetPassword = lazy(() => import('../components/Login/ResetPassword'))
const BindPhone = lazy(() => import('../components/Login/BindPhone'))
// 首页 无讼研究院
const AppHome = lazy(() => import('../components/AppHome'))

const TopCourseList = lazy(() => import('../components/CommonHome/WeekTopCourses/TopCourseList'))
const LiveMoreList = lazy(() => import('../components/CommonHome/PreviousPeriodLiveCourse/List'))
const ColumnMoreList = lazy(() => import('../components/CommonHome/HighQualityColumn/List'))
const LabelCourseList = lazy(() => import('../components/CommonHome/LabelList'))
const LiveListPm = lazy(() => import('../components/CommonHome/LiveListPm'))
const SeckillList = lazy(() => import('../components/Seckill/List'))
const SearchCourse = lazy(() => import('../components/CommonHome/SearchCourse'))

//H5首页
const Home = lazy(() => import('../components/Home'))
// 直播详情
const CourseDetail = lazy(() => import('../components/LiveCourseDetail'))

//专栏详情
const ColumnDetail = lazy(() => import('../components/ColumnDetail'))

//视频详情
const VideoDetail = lazy(() => import('../components/VideoDetail'))

//面授课详情
const FaceDetail = lazy(() => import('../components/FaceDetail'))

const Live = lazy(() => import('../components/Live'))

// 下载页
const DownloadPage = lazy(() => import('../components/DownloadPage'))

//课程学习
const CourseDuration = lazy(() => import('../components/CourseDuration'))
// 支付页面
const AppPayMent = lazy(() => import('../components/AppPayMent/PayMent'))
// 支付结果页面
const AppPayResult = lazy(() => import('../components/AppPayMent/PayResult'))

// 微站支付页面
const Payment = lazy(() => import('../components/Payment/Payment'))
// 支付结果页面
const PayResult = lazy(() => import('../components/Payment/PayResult'))
const PaySuccess = lazy(() => import('../components/Payment/PaySuccess'))
/* 消费记录 */
const MyOrderList = lazy(() => import('../components/MyOrderList'))
const Evaluate = lazy(() => import('../components/MyOrderList/Evaluate'))

// web端直播
const WebLive = lazy(() => import('../components/WebLive/WebLive'))

// 我的优惠券
const MyVoucherList = lazy(() => import('../components/Voucher/MyVoucherList'))
// 历史卡券
const MyVoucherHistory = lazy(() => import('../components/Voucher/MyVoucherHistory'))
// 优惠券详情
const VoucherDetail = lazy(() => import('../components/Voucher/VoucherDetail'))
// 优惠券分享
const VoucherShareDetail = lazy(() => import('../components/Voucher/ShareDetail'))
// 领券中心
const VoucherCenter = lazy(() => import('../components/Voucher/Center'))
// 兑换
const Redeem = lazy(() => import('../components/Redeem'))
const RedeemHistory = lazy(() => import('../components/Redeem/RedeemHistory'))

// 多栏标签列表
const TagList = lazy(() => import('../components/TagList'))
// 活动页
const SixthAnniversary = lazy(() => import('../components/SixthAnniversary'))
const DoubleEleventh = lazy(() => import('../components/DoubleEleventh'))
const MayDay = lazy(() => import('../components/MayDay'))
const CredentialGuide = lazy(() => import('../components/CredentialGuide'))

const CollectionDetail = lazy(() => import('../components/CollectionDetail'))

const AudioDetail = lazy(() => import('../components/AudioDetail'))

const LawSocietySection = lazy(() => import('../components/LawSocietySection'))
const LawStudyList = lazy(() => import('../components/LawSocietySection/Learning'))
const LawSocietyCourse = lazy(() => import('../components/LawSocietyCourse'))
const DownVoucher = lazy(() => import('../components/LawSocietySection/DownVoucher'))

const Foundation = lazy(() => import('../components/Activities/Foundation'))
const FoundationWish = lazy(() => import('../components/Activities/Foundation/Wishes'))
const FoundationLive = lazy(() => import('../components/Activities/Foundation/Live'))
const FoundationDonation = lazy(() => import('../components/Activities/Foundation/Donations'))
const FoundationStory = lazy(() => import('../components/Activities/Foundation/Stories'))
const FoundationLottery = lazy(() => import('../components/Activities/Foundation/Lottery'))
const FoundationMyPrice = lazy(() => import('../components/Activities/Foundation/MyPrice'))

// 无讼律师APP帮助中心
const HelpCenter = lazy(() => import('../components/HelpCenter'))

// 我的
const My = lazy(() => import('../components/My'))
const ChangeUserName = lazy(() => import('../components/My/ChangeUserName'))
const AccountSetting = lazy(() => import('../components/My/AccountSetting'))
const ChangePsd = lazy(() => import('../components/My/AccountSetting/ChangePsd'))
const ChangePhone = lazy(() => import('../components/My/AccountSetting/ChangePhone'))

const DoubleTwelve = lazy(() => import('../components/DoubleTwelve'))

const AnnualReport = lazy(() => import('../components/AnnualReport'))
const AnnualReportResult = lazy(() => import('../components/AnnualReport/ResultPage'))

// 拼团
const GroupBuyingList = lazy(() => import('../components/CommonHome/GroupBuying/List'))
const GroupBuyingDetail = lazy(() => import('../components/CommonHome/GroupBuying/Detail'))

const Logout = lazy(() => import('../components/Login/Logout'))

const RIP = lazy(() => import('../components/RIP'))
// 文章专栏列表
const ArticleColumn = lazy(() => import('../components/ArticleColumnList'))
const ArticleColumnDetail = lazy(() => import('../components/ArticleColumnList/Detail'))

// 律协专区搜索课程
const LawCourseSearch = lazy(() => import('../components/LawSocietyCourse/LawCourseSearch'))
const AllSortCourses = lazy(() => import('../components/CommonHome/AllSortCourses'))

// 合作精选（第三方合作课程）
const PartnerCourse = lazy(() => import('../components/PartnerCourse'))

const VipLandingPage = lazy(() => import('../components/VipLandingPage'))

const VipEquityPage = lazy(() => import('../components/VipEquity'))

const VipEntry = lazy(() => import('../components/VipEntry'))

// const Invoice = lazy(() => import('../components/Invoice'))
//
// const InvoicingList = lazy(() => import('../components/Invoice/InvoicingList'))
//
// const InvoiceForm = lazy(() => import('../components/Invoice/InvoiceForm'))
//
// const InvoiceDetail = lazy(() => import('../components/Invoice/InvoiceDetail'))
//
// const InvoiceResult = lazy(() => import('../components/Invoice/InvoiceResult'))
//
// const InvoiceHistory = lazy(() => import('../components/Invoice/InvoiceHistory'))
//
// const InvoiceHistoryDetail = lazy(() => import('../components/Invoice/InvoiceHistoryDetail'))
//
// const InvoiceCorrelationOrders = lazy(() => import('../components/Invoice/InvoiceCorrelationOrders'))

export default (): JSX.Element => {
    return (
        <BrowserRouter>
            <Suspense fallback={<Loading />}>
                <ErrorBoundary>
                    <App>
                        <Switch>
                            <Route exact path={ROUTES.ROOT} component={Home} />
                            <Route path={ROUTES.APP_HOME} component={AppHome} />
                            <Route path={ROUTES.REGISTER} component={Register} />
                            <Route path={ROUTES.AGREEMENT} component={Agreement} />
                            {/* <Route path={ROUTES.PRIVACY} component={Privacy} /> */}
                            <Route path={ROUTES.LOGIN} component={Login} />
                            <Route path={ROUTES.OAUTH_WEIXIN} component={OAuth} />
                            <Route path={ROUTES.RESET_PASSWORD} component={ResetPassword} />
                            <Route path={ROUTES.BIND_PHONE} component={BindPhone} />
                            <Route path={ROUTES.HOME} component={Home} />
                            <Route path={ROUTES.LIVE_MORE} component={LiveMoreList} />
                            <Route path={ROUTES.COLUMN_MORE} component={ColumnMoreList} />
                            <Route path={ROUTES.TOP_COURSE_LIST} component={TopCourseList} />
                            <Route path={ROUTES.LABEL_COURSE_LIST} component={LabelCourseList} />
                            <Route path={ROUTES.LIVE_LIST_PM} component={LiveListPm} />
                            <Route path={ROUTES.SECKILL_LIST} component={SeckillList} />
                            <Route path={ROUTES.SEARCH_COURSE} component={SearchCourse} />
                            <Route path={ROUTES.FACE2FACE_DETAIL} component={FaceDetail} />
                            <Route path={ROUTES.LIVE_DETAIL} component={CourseDetail} />
                            <Route path={ROUTES.COLUMN_DETAIL} component={ColumnDetail} />
                            <Route path={ROUTES.VIDEO_DETAIL} component={VideoDetail} />
                            <Route path={ROUTES.WATCH_LIVE} component={Live} />
                            <Route path={ROUTES.MY_COURSE_LIST} component={CourseDuration} />
                            <Route path={ROUTES.APP_PAY} component={AppPayMent} />
                            <Route path={ROUTES.APP_PAY_RESULT} component={AppPayResult} />
                            <Route path={ROUTES.WEIXIN_PAY} component={Payment} />
                            <Route path={ROUTES.WEIXIN_PAY_RESULT} component={PayResult} />
                            <Route path={ROUTES.WEIXIN_PAY_SUCCESS} component={PaySuccess} />
                            <Route path={ROUTES.ORDER_LIST} component={MyOrderList} />
                            <Route path={ROUTES.EVALUATE} component={Evaluate} />
                            <Route path={ROUTES.MY_VOUCHER_LIST} component={MyVoucherList} />
                            <Route path={ROUTES.MY_VOUCHER_HISTORY} component={MyVoucherHistory} />
                            <Route path={ROUTES.VOUCHER_DETAIL} component={VoucherDetail} />
                            <Route path={ROUTES.VOUCHER_SHARE_DETAIL} component={VoucherShareDetail} />
                            <Route path={ROUTES.VOUCHER_CENTER} component={VoucherCenter} />
                            <Route path={ROUTES.REDEEM} component={Redeem} />
                            <Route path={ROUTES.REDEEM_HISTORY} component={RedeemHistory} />
                            <Route path={ROUTES.MY} component={My} />
                            <Route path={ROUTES.CHANGE_NAME} component={ChangeUserName} />
                            <Route path={ROUTES.ACCOUNT_SETTING} component={AccountSetting} />
                            <Route path={ROUTES.CHANGE_PSD} component={ChangePsd} />
                            <Route path={ROUTES.CHANGE_PHONE} component={ChangePhone} />
                            <Route path={ROUTES.TAG_LIST} component={TagList} />
                            <Route path={ROUTES.DOWNLOAD_APP} component={DownloadPage} />
                            <Route exact path={`${ROUTES.WEB_LIVE}/:videoId`} component={WebLive} />
                            <Route path={ROUTES.SIXTH} component={SixthAnniversary} />
                            <Route path={ROUTES.DOUBLE_ELEVENTH} component={DoubleEleventh} />
                            <Route path={ROUTES.COLLECTION_DETAIL} component={CollectionDetail} />
                            <Route path={ROUTES.LAW_SOCIETY_SECTION} component={LawSocietySection} />
                            <Route path={ROUTES.LAW_STUDY_LIST} component={LawStudyList} />
                            <Route path={ROUTES.LAW_SOCIETY_COURSE} component={LawSocietyCourse} />
                            <Route path={ROUTES.DOWN_VOUCHER} component={DownVoucher} />
                            <Route path={ROUTES.AUDIO_DETAIL} component={AudioDetail} />
                            <Route path={ROUTES.DOUBLE_TWELVE} component={DoubleTwelve} />
                            <Route path={ROUTES.MAY_DAY} component={MayDay} />
                            <Route path={ROUTES.CREDENTIAL_GUIDE} component={CredentialGuide} />
                            <Route path={ROUTES.HELP_CENTER} component={HelpCenter} />
                            <Route path={ROUTES.ANNUAL_REPORT} component={AnnualReport} />
                            <Route path={ROUTES.ANNUAL_REPORT_RESULT} component={AnnualReportResult} />
                            <Route path={ROUTES.GROUP_BUYING_LIST} component={GroupBuyingList} />
                            <Route path={ROUTES.GROUP_BUYING_DETAIL} component={GroupBuyingDetail} />
                            <Route path={ROUTES.ARTICLE_COLUMN} component={ArticleColumn} />
                            <Route path={ROUTES.ARTICLE_COLUMN_DETAIL} component={ArticleColumnDetail} />
                            <Route path={ROUTES.LAW_COURSE_SEARCH} component={LawCourseSearch} />
                            <Route path={ROUTES.LOGOUT} component={Logout} />
                            <Route path={ROUTES.RIP} component={RIP} />
                            <Route path={ROUTES.ALL_SORT} component={AllSortCourses} />
                            <Route path={ROUTES.FOUNDATION} component={Foundation} />
                            <Route path={ROUTES.FOUNDATION_WISHES} component={FoundationWish} />
                            <Route path={ROUTES.FOUNDATION_DONATION} component={FoundationDonation} />
                            <Route path={ROUTES.FOUNDATION_STORY} component={FoundationStory} />
                            <Route path={ROUTES.FOUNDATION_LIVE} component={FoundationLive} />
                            <Route path={ROUTES.FOUNDATION_LOTTERY} component={FoundationLottery} />
                            <Route path={ROUTES.FOUNDATION_MY_PRICE} component={FoundationMyPrice} />
                            <Route path={ROUTES.PARTNER_COURSE} component={PartnerCourse} />
                            <Route path={ROUTES.VIP_LANDING_PAGE} component={VipLandingPage} />
                            <Route path={ROUTES.VIP_EQUITY_PAGE} component={VipEquityPage} />
                            <Route path={ROUTES.VIP_ENTRY} component={VipEntry} />
                            {/*<Route path={ROUTES.INVOICE} component={Invoice} />*/}
                            {/*<Route path={ROUTES.INVOICING_LIST} component={InvoicingList} />*/}
                            {/*<Route path={ROUTES.INVOICE_FORM} component={InvoiceForm} />*/}
                            {/*<Route path={ROUTES.INVOICE_DETAIL} component={InvoiceDetail} />*/}
                            {/*<Route path={ROUTES.INVOICE_RESULT} component={InvoiceResult} />*/}
                            {/*<Route path={ROUTES.INVOICE_HISTORY} component={InvoiceHistory} />*/}
                            {/*<Route path={ROUTES.INVOICE_HISTORY_DETAIL} component={InvoiceHistoryDetail} />*/}
                            {/*<Route path={ROUTES.INVOICE_CORRELATION_ORDERS} component={InvoiceCorrelationOrders} />*/}
                            <Route component={ErrorBoundary} />
                        </Switch>
                    </App>
                </ErrorBoundary>
            </Suspense>
        </BrowserRouter>
    )
}
