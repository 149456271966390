export const GET_COURSE_DETAIL = '/api/ws/college/outer/h5/live/detail'
export const GET_COURSE_DOWNLOAD_LIST = '/api/ws/college/app/live/file/list'
export const GET_COURSE_ROOM_ID = '/chatroom/room/addr'
export const GET_ACC_ID_BY_HANUKKAH_ID = '/cquser/accids'
export const UPLOAD_IMG = '/api/ws/app_interface/outer/upload/img'

export const WEB_LOGIN = '/api/ws/college/wap/user/outer/login' // 网页登录
export const WEIXIN_OAUTH = '/api/ws/college/outer/h5/wechat/oauth/login' // 微信授权跳转地址
export const WEIXIN_LOGIN = '/api/ws/college/wap/user/outer/wx/login' // 微信登录
export const REGISTER = '/api/ws/college/wap/user/outer/register' // 用户注册
export const GET_SMS_CODE = '/api/ws/college/sms/outer/send/code' // 用户注册验证码
export const RESET_PASSWORD = '/api/ws/college/wap/user/outer/resetPassword' // 重置密码
export const OUTER_BIND_PHONE = '/api/ws/college/wap/user/outer/wx/bind' // 未登录微信绑定
export const BIND_PHONE = '/api/ws/college/wap/user/wx/bind' // 已登录微信绑定
export const UNBIND_PHONE = '/api/ws/college/wap/user/wx/unbind' // 微信解绑
export const GET_USER_INFO = '/api/ws/college/wap/user/account' // 获取当前用户信息
export const LOGOUT = '/api/ws/college/wap/user/logout' // 注销
export const GET_USER_ID = '/api/ws/college/outer/find/userId?token='
export const IS_REGISTER = '/api/ws/college/outer/user/exist/'

//无讼研究院首页
export const GET_FIRST_PART_HOME_DATA = '/api/ws/college/outer/home/first' //首页第yi部分
export const GET_COLUMN_HOME_LIVE_WEEKTOP = '/api/ws/college/outer/home/second' //首页第二部分（今日公开课、周榜）
export const GET_COLUMN_HOME_THIRD = '/api/ws/college/outer/home/third' //首页第三部分（精品直播、精品专栏、标签推荐）
export const GET_COLUMN_HOME_THIRD_COURSE_CLICK = '/api/ws/college/outer/home/third/course' //首页第三部分（标签推荐）-点击标签获取课程列表

//专栏课
export const GET_COLUMN_DETAIL = '/api/ws/college/outer/h5/special/detail'
export const GET_COLUMN_LIST = '/api/ws/college/outer/h5/special/directory/list'
//'/api/ws/college/outer/h5/special/detail/list'
// '/api/ws/college/outer/h5/special/directory/list'
export const GET_COLUMN_FILE_LIST = '/api/ws/college/outer/h5/special/file/list' // 专栏课件列表

//直播/视频地址
export const GET_WAP_LIVE_COURSE_DETAIL = '/api/ws/college/wap/live/play'
export const GET_WAP_VIDEO_COURSE_DETAIL = '/api/ws/college/wap/course/video/detail'
export const SEND_COURSE_FILE = '/api/ws/college/app/live/file/send' // 发送课件
export const LIVE_REMIND = '/api/ws/college/live/remind'

//视频详情
export const GET_VIDEO_DETAIL = '/api/ws/college/outer/h5/video/detail'
export const GET_VIDEO_COURSE_DOWNLOAD_LIST = '/api/ws/college/outer/h5/video/file/list'

//面授详情
export const GET_FACE_DETAIL = '/api/ws/college/outer/h5/face/detail'
export const GET_STUDENT_INFO = '/api/ws/college/h5/student/simple/info'
export const GET_LEADNING_LIST = '/api/ws/college/list/duration'
export const GET_PURCHASED_LIST = '/api/ws/college/h5/payed/course/list'

// 秒杀
export const GET_SECKILL_HOME_LIST = '/api/ws/college/outer/seckill/home/list' // 首页秒杀列表
export const GET_SECKILL_LIST = '/api/ws/college/outer/seckill/list' // 首页秒杀列表
export const GET_COURSE_SECKILL_INFO = '/api/ws/college/outer/seckill/info' // 课程秒杀详情

// 评论系统
export const GET_COMMENT_HISTORY_LIST = '/api/ws/college/course/comment'
export const SEND_COMMENT = '/api/ws/college/course/comment/save'
export const REVOKE_COMMENT = '/api/ws/college/course/comment/revoke'
export const GET_CURRENT_COMMENT_COUNT = 'api/ws/college/course/comment/number'
export const MUTE_USER = '/api/ws/college/mute/single'
export const GET_MUTE_STATUS = '/api/ws/college/mute/user/status'

// web端直播
export const POST_WEB_LOGIN = '/api/ws/college/outer/user/login'
export const GET_WEB_LIVE_COURSE_DETAILS = '/api/ws/college/live/play'
export const GET_WEB_VIDEO_COURSE_DETAILS = '/api/ws/college/video/play'
// export const GET_WEB_COURSE_PAY = '/api/ws/college/valid/pay';
export const GET_WEB_USER_INFO = '/api/ws/college/user'
export const GET_WEB_SIGN_EXIST = '/api/ws/college/sign/exist'
export const POST_WEB_USER_SIGN = '/api/ws/college/course/sign'
export const APP_PLAY_TRACKING_WEBSOCKET = '/message/app/course/live' // websocket 地址
export const WEB_PLAY_TRACKING_SOCKET = '/message/pc/course/live' // pc 看直播 socket
export const WEB_PLAY_REMINDER = '/api/ws/college/h5/course/remind' // 学习提醒
export const UPDATE_STUDY_PROGRESS = '/api/ws/college/progress/addOrModify' // 添加学习进度

// 支付系统
export const PAY_MENT = '/api/ws/college/h5/pay'
export const POLLING = '/api/ws/college/outer/h5/pay/state'
export const W_POLLING = '/api/ws/college/h5/payCourse/state'
export const W_PAY_MENT = '/api/ws/college/h5/payCourse'
export const PAY_FREE = '/api/ws/college/h5/payCourse/free'

export const MY_ORDER_LIST = '/api/ws/college/wap/pay/records' // 消费记录
export const EVALUATE_LABELS = '/api/ws/college/app/evaluate/edit'
export const SAVE_EVALUATE = '/api/ws/college/app/evaluate/save'
export const SHOW_EVALUATE = '/api/ws/college/app/evaluate/show'

export const GET_FORM_INFO = '/api/ws/college/h5/information/form/info'
export const SAVE_FORM_INFO = '/api/ws/college/h5/information/form/collect'
export const HOT_CITY = '/api/ws/college/outer/addr/hot'
export const AREA_DATA = '/api/ws/college/outer/addr'

// 兑换码
export const REDEEM_CODE = '/api/ws/college/app/redeemcode/convert'
export const AUTO_REDEEM = '/api/ws/college/app/redeemcode/auto/convert'
export const GET_REDEEM_HISTORY = '/api/ws/college/app/redeemcode/convert/record'

// 二次分享
export const GET_SHARE_SIGNATURE = '/api/ws/college/outer/h5/wechat/signature'

// 领券中心
export const COLLECT_CENTER = '/api/ws/college/h5/coupon/center' // 领券中心
export const COUPON_SINGLE_DETAIL = '/api/ws/college/h5/outer/coupon/share'
export const GET_RELATE_COURSE_LIST = '/api/ws/college/h5/outer/coupon/courses' // 优惠券关联课程
export const LOGIN_COLLECT = '/api/ws/college/h5/outer/coupon/login' // 登陆领取
export const REGISTER_COLLECT = '/api/ws/college/h5/outer/coupon/register'
export const GET_VERIFICATION_CODE = '/api/ws/college/h5/outer/verificationCode'
export const COURSE_COUPON_LIST = '/api/ws/college/h5/outer/course/coupons' // 课程关联优惠券
export const COURSE_COUPON_LIST_BY_USER = '/api/ws/college/wap/coupon/course/available' // 课程 - 用户关联优惠券
export const COLLECT_COUPON = '/api/ws/college/h5/obtain' // 领取优惠券

// 微站优惠券
export const MY_COUPON_LIST = '/api/ws/college/wap/coupon/center' // 卡券中心
export const MY_COUPON_HISTORY = '/api/ws/college/wap/coupon/history' // 历史卡券
export const COUPON_DETAIL = '/api/ws/college/wap/coupon' // 详情
export const AVAILABLE_COUPON_LIST = '/api/ws/college/wap/coupon/course/receive/available' // 课程可用优惠券
export const OPTIMAL_COUPON = '/api/ws/college/wap/coupon/optimal' // 课程自动使用的优惠券

// 直播带货
export const GET_SKU_LIST = '/api/ws/college/h5/wares/list'
export const RECOMMEND_SKU = '/api/ws/college/h5/wares/push'
export const SKU_CLICK = '/api/ws/college/h5/wares/hit'

// 可配置标签分组页面（广东律协）
export const GET_PAGE_LABEL_INFO = '/api/ws/college/outer/home/lawsociety'
export const GET_LABEL_COURSE_LIST = '/api/ws/college/outer/home/lawsociety/courses'

// 首页更多
export const GET_PREVIOUS_LIVE_MORE_LIST = '/api/ws/college/outer/live/live_back'
export const GET_LIVE_MORE_LABELS = '/api/ws/college/outer/app/course/labels'
export const GET_COLUMN_MORE_LABELS = '/api/ws/college/outer/course/special/list'
export const GET_TOP_COURSES_LIST = '/api/ws/college/outer/home/week_top'
export const GET_LABELS_MORE_LIST = '/api/ws/college/outer/home/courses'
export const GET_LIVE_LIVING_LIST = '/api/ws/college/outer/live/living'
export const GET_COLUMN_ENTRY_MSG = '/api/ws/college/outer/course/special'
export const CLOSE_AD = '/api/ws/college/outer/home/ad_view'
export const SEARCH_COURSENAME = '/api/ws/college/outer/home/courses/search'
export const SEARCH_HOT_WORD = '/api/ws/college/outer/home/search/hot_word'
export const HISTORY_SEARCH_KEY = '/api/ws/college/outer/home/search/remind'
export const HISTORY_SEARCH_WORDS = '/api/ws/college/outer/home/search/history'
export const CLEAR_HISTORY_SEARCH = '/api/ws/college/outer/home/search/history/clear'

// 我的
export const CHANGE_USER_NAME = '/api/ws/college/wap/user/update'
export const CHANGE_PSD = '/api/ws/college/wap/user/modifyPassword'
export const CHANGE_PHONE = '/api/ws/college/wap/user/change/phone'

export const GET_AUTH_KEY_INFO = '/api/ws/college/getPlayerParams'

export const COLLECTION_DETAIL = '/api/ws/college/outer/h5/collection/detail'
export const GET_DOUBLE_ELEVENTH = '/api/ws/college/outer/activity/eleven'
export const GET_ALL_DOUBLE_ELEVENTH_COUPON = '/api/ws/college/h5/coupon/eleven/getAll'

// 音频
export const GET_AUDIO_DETAIL = '/api/ws/college/outer/h5/audio/detail'
export const GET_AUDIO_LIST = '/api/ws/college/outer/h5/audio/detail/list'
export const GET_AUDIO_FILE_LIST = '/api/ws/college/outer/h5/audio/files'

// 律协专区
export const GET_LAW_SOCIETY_UESR = '/api/ws/college/lawyer/info'
export const GET_RECENT_STUDY = '/api/ws/college/lawyer/study/info'
export const GET_CURRENT_LIST = '/api/ws/college/lawyer/study/info/list'
export const GET_TOP_SOCIETY_COURSE = '/api/ws/college/society/course'
export const GET_LAW_SOCIETY_COURSE_LIST = '/api/ws/college/society/course/list'
export const GET_LAW_SOCIETY_INFO = '/api/ws/college/society/labels'
export const GET_LAWYER_LEARN_VOUCHER = '/api/ws/college/study/voucher'
export const GET_ISHAVE_LEARN_VOUCHER = '/api/ws/college/study/voucher/info'
export const LAW_SEARCH_COURSE = '/api/ws/college/society/course/search'
export const GET_LEARN_TIME_RANGE = '/api/ws/college/lawSociety/contract/history'

export const NEW_YEAR_COLLEGE = '/api/ws/college/outer/activity/newYear'

export const GET_ANNUALREPORT = '/api/ws/college/user/annual/report'
export const GET_REGISTER_DAYS = '/api/ws/college/user/annual/register/days'
export const GET_ANNUALREPORT_IMAGE = '/api/ws/college/user/annual/report/image'

export const GET_LAST_SOCIETY_INFO = '/api/ws/college/study/voucher/lastsociety/info'
export const GET_LAST_SOCIETY_DOWNLOAD = '/api/ws/college/study/voucher/lastsociety/download'

export const QUERY_BALANCE = '/api/ws/college/wscoin/h5/balance'
export const QUICK_PAY = '/api/ws/college/wscoin/h5/pay'

// 拼团
export const GROUP_BUYING_HOME = '/api/ws/college/outer/activity/home/list'
export const GROUP_BUYING_LIST = '/api/ws/college/outer/activity/list'
export const GROUP_MEMBERS_LIST = '/api/ws/college/outer/activity/list/group/purchase/members'
export const GROUP_DETAIL = '/api/ws/college/outer/activity/find/group/purchase/detail'

export const MAY_DAY_DATA = '/api/ws/college/outer/activity/landingPage'

export const GET_SIGNIN_NUM = '/api/ws/college/wap/sign/countNum'

export const JUDGE_IS_HIDE_COUPON = '/api/ws/college/outer/app/config/cp_swt'

// RIP
export const RIP_LIST = '/api/ws/college/outer/h5/wechat/comment/list'
export const SAVE_RIP = '/api/ws/college/outer/h5/wechat/comment/save'
export const RIP_NUM = '/api/ws/college/outer/redis/inc'
export const ACTRCCLE_COLUMN_LIST = '/api/ws/app_interface/outer/article/'

export const ALL_COURSE_LABELS = '/api/ws/college/outer/home/course/labels'
export const ALL_COURSE_LIST = '/api/ws/college/outer/home/course/list'

// 认证律师活动页
export const IS_LAWYER = '/api/ws/college/outer/user/isLawyer'
export const GET_COURSES = '/api/ws/college/user/lawyer/receive'
export const GET_CREDENTIAL_USER_INFO = '/passover/app/users/auth/status'

export const GET_LEARNING_DURATION = '/api/ws/college/app/course/learn/statistics '

// 蒋律基金开幕式
export const FOUNDATION_WORDS = '/api/ws/college/foundation/outer/light/words'
export const FOUNDATION_LIST = '/api/ws/college/foundation/outer/light/words/list?sort=1&size='
export const FOUNDATION_SAVE = '/api/ws/college/foundation/outer/light/words/save'
export const FOUNDATION_CONFIG = '/api/ws/college/foundation/outer/sys/configs'
export const FOUNDATION_STORY_SAVE = '/api/ws/college/foundation/outer/story/save'
export const FOUNDATION_LABELS = '/api/ws/college/foundation/outer/impression/labels'
export const FOUNDATION_LABELS_SAVE = '/api/ws/college/foundation/outer/impression/save'
export const FOUNDATION_DOWNLOAD_COUNT = '/api/ws/college/foundation/outer/impression/download/count'
export const FOUNDATION_GET_MY_LABELS = '/api/ws/college/foundation/outer/impression/info?openId='
export const FOUNDATION_HELP_COUNT = '/api/ws/college/foundation/outer/help?openId='
export const FOUNDATION_GET_BADGE = '/api/ws/college/foundation/outer/gift/getChance?openId='
export const FOUNDATION_QUERY_BADGE = '/api/ws/college/foundation/outer/gift/myChances?openId='
export const FOUNDATION_QUERY_CHANCE = '/api/ws/college/foundation/outer/gift/unusedChance?openId='
export const FOUNDATION_LOTTERY_GIFTS = '/api/ws/college/foundation/outer/gift/info'
export const FOUNDATION_LOTTERY_DRAW = '/api/ws/college/foundation/outer/gift/draw?openId='
export const FOUNDATION_QUERY_MY_PRICE = '/api/ws/college/foundation/outer/gift/getGift?openId='
export const FOUNDATION_LOTTERY_UPDATE_WINNER = '/api/ws/college/foundation/outer/gift/updateWinner'

// 合作精选
export const STUDY_PART_COURSE = '/api/ws/college/outer/home/study/part/course'
// 会员
export const LANDING_DETAIL = '/api/ws/college/outer/h5/member/landing/page'
export const VIP_EQUITY = '/api/ws/college/h5/my/members'
export const IS_BUY_VIP = '/api/ws/college/h5/member/purchased'

// 发票
export const INVOICE_ORDERS = '/api/ws/college/h5/invoice/orders'
export const INVOICE_HISTORY = '/api/ws/college/h5/invoice/history'
export const INVOICE_ALLOCATE = '/api/ws/college/h5/invoice/allocate'
export const LAST_INVOICE = '/api/ws/college/h5/invoice/user/last'
export const INVOICE_HISTORY_DETAIL = '/api/ws/college/h5/invoice/info'
export const INVOICE_RELATION_ORDERS = '/api/ws/college/h5/invoice/relationOrders'
