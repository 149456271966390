import React, { PropsWithChildren, useState } from 'react'
import { getSession, SessionProvider } from './components/common/SessionValidator'
import { FROM_TYPE } from './constants/types'
import { Location } from 'history'
import qs from 'qs'
import { ROUTES } from './constants/routes'
import { useRefreshBFCache } from './utils/useRefreshBFCache'
import { useOnHistoryChange } from './utils/useOnHistoryChange'

declare global {
    type NativeMessageType = {
        eventName: string
        data?: {
            [key: string]: any
        }
    }

    interface CallNativeInterface {
        postMessage(args: NativeMessageType): void
        postMessage(args: string): void
        postMessage(args: unknown): void
    }

    interface Window {
        /**
         * iOS interface
         */
        webkit?: {
            messageHandlers?: {
                onJsCallNative?: CallNativeInterface
                onHidenBottom: CallNativeInterface
                onInjectFetchImgUrlsJS: CallNativeInterface
            }
        }
        gio: any
        webViewRequestSend: boolean // GIO设置APP内禁用
        /**
         * Android interface
         */
        onJsCallNative: CallNativeInterface
        /**
         * app 交互用方法集合
         * 需要在页面加载时挂载，页面卸载时销毁
         */
        _wsJsBridge: {
            updateCatalog?: () => void
            refresh?: () => void
        }

        // old implementations
        imageListener?: {
            onHidenBottom: (arg: boolean) => void
            openCourseImage: (src: string) => void
        }

        getData: {
            getSignIn: () => void
        }

        getSignIn?: () => void

        jsCallApp?: {
            resize: (height: number) => void
        }
    }
}

window._wsJsBridge = {}
export function callNative(message: NativeMessageType): void {
    if (/(iPhone|iPad|iPod|iOS)/i.test(window.navigator?.userAgent)) {
        console.log('iOS')
        window.webkit?.messageHandlers?.onJsCallNative?.postMessage(message)
    } else if (/(Android)/i.test(navigator.userAgent)) {
        console.log('Android')
        window.onJsCallNative?.postMessage(JSON.stringify(message))
    } else {
        console.error('not in app')
    }
}

type GlobalConfig = {
    currency: string
    fromType: FROM_TYPE
}
export const GlobalConfigContext = React.createContext<GlobalConfig>({
    currency: '元',
    fromType: FROM_TYPE.APP
})

function App(props: PropsWithChildren<any>): JSX.Element {
    const [currency, setCurrency] = useState<string>('元')
    const [fromType, setFromType] = useState<FROM_TYPE>(FROM_TYPE.APP)

    function updateFromType(location: Location): void {
        const params = qs.parse(location.search.substring(1))
        const from = params['from']
        if (from === FROM_TYPE.SHARE) {
            setCurrency('元')
            setFromType(FROM_TYPE.WEB_APP)
        } else if (from === FROM_TYPE.WEB_APP) {
            setCurrency('元')
            setFromType(FROM_TYPE.WEB_APP)
            // Gio上传清除用户ID
            const userId = getSession()?.userId
            if (userId) {
                window.gio('setUserId', userId)
            } else {
                window.gio('clearUserId')
            }
        } else if (
            location.pathname === ROUTES.ROOT ||
            location.pathname === ROUTES.HOME ||
            location.pathname === ROUTES.VOUCHER_SHARE_DETAIL
        ) {
            // 微站专属页面
            setCurrency('元')
            setFromType(FROM_TYPE.WEB_APP)
        } else {
            setCurrency('无讼币')
            setFromType(FROM_TYPE.APP)
        }

        // APP内GIO上传用户ID
        if (from === FROM_TYPE.APP) {
            let userId = '无'
            if (params['userId']) {
                userId = `${params['userId']}`
                window.gio('hybridSetUserId', userId)
            } else {
                window.gio('hybridClearUserId')
                // if (params['token']) {
                //     Axios.get(GET_USER_ID + params['token']).then(res => {
                //         if (res.data) {
                //             userId = res.data
                //         }
                //         window.gio('hybridSetUserId', userId)
                //     })
                // } else {
                //     window.gio('hybridSetUserId', userId)
                // }
            }
        }
    }

    useOnHistoryChange(updateFromType)
    useRefreshBFCache()

    return (
        <GlobalConfigContext.Provider value={{ currency, fromType }}>
            <SessionProvider>{props.children}</SessionProvider>
        </GlobalConfigContext.Provider>
    )
}

export default App
