import {
    ChatroomInfo,
    ChatroomInstance,
    ChatroomObject,
    Credentials,
    Member,
    Message,
    MessageFilter,
    MessageTransformer,
    UserInfo
} from '../types'
import { createAction } from '@reduxjs/toolkit'
import { VirtuosoMethods } from 'react-virtuoso'

const UPDATE_NIM = 'UPDATE_NIM'
const UPDATE_LOADING = 'UPDATE_LOADING'
const UPDATE_LIST_LOADING = 'UPDATE_LIST_LOADING'
const UPDATE_LOGIN_INFO = 'UPDATE_LOGIN_INFO'
const INITIALIZE_CHATROOM = 'INITIALIZE_CHATROOM'
const GET_CHATROOM_MAP = 'GET_CHATROOM_MAP'
const GET_CHATROOM = 'GET_CHATROOM'
const ENTER_CHATROOM = 'ENTER_CHATROOM'
const EXIT_CHATROOM = 'EXIT_CHATROOM'
const UPDATE_CHATROOM = 'UPDATE_CHATROOM'
const PREPEND_RAW_MESSAGE = 'PREPEND_RAW_MESSAGE' // 头部添加原始消息
const APPEND_RAW_MESSAGE = 'APPEND_RAW_MESSAGE' // 尾部追加原始消息
const PREPEND_MESSAGE_LIST = 'PREPEND_MESSAGE_LIST' // 更新处理后的消息列表
const APPEND_MESSAGE_LIST = 'APPEND_MESSAGE_LIST' // 更新处理后的消息列表
const UPDATE_DYNAMIC_MESSAGE_LIST = 'UPDATE_DYNAMIC_MESSAGE_LIST' // 更新动态消息列表
const MODIFY_MESSAGE = 'MODIFY_MESSAGE'
const REMOVE_MESSAGE = 'REMOVE_MESSAGE'
const UPDATE_CHATROOM_MEMBER = 'UPDATE_CHATROOM_MEMBER'
const UPDATE_MY_INFO = 'UPDATE_MY_INFO'
const UPDATE_USER_INFO = 'UPDATE_USER_INFO'
const UPDATE_SCROLL_POSITION = 'UPDATE_SCROLL_POSITION'
const UPDATE_QUESTION_MODE = 'UPDATE_QUESTION_MODE'
const UPDATE_REPLYING_MESSAGE = 'UPDATE_REPLYING_MESSAGE'
const SET_LIST_REF = 'SET_LIST_REF'
const UPDATE_LAST_READ_POSITION = 'UPDATE_LAST_READ_POSITION'
const UPDATE_VISIBLE_MESSAGE_COUNT = 'UPDATE_VISIBLE_MESSAGE_COUNT'
const UPDATE_TOTAL_VISIBLE_MESSAGE_COUNT = 'UPDATE_TOTAL_VISIBLE_MESSAGE_COUNT'
const UPDATE_SPECIALIZED_MESSAGE_FILTER = 'UPDATE_SPECIALIZED_MESSAGE_FILTER'
const UPDATE_EXTERNAL_MESSAGE_FILTER = 'UPDATE_EXTERNAL_MESSAGE_FILTER'
const ADD_MESSAGE_TRANSFORMER = 'ADD_MESSAGE_TRANSFORMER'

export const updateNIM = createAction<any>(UPDATE_NIM)
export const updateLoading = createAction<boolean>(UPDATE_LOADING)
export const updateListLoading = createAction<boolean>(UPDATE_LIST_LOADING)
export const updateLoginInfo = createAction<Credentials>(UPDATE_LOGIN_INFO)
export const updateChatroomInstance = createAction<{
  id: number
  chatroom: ChatroomInstance
}>(INITIALIZE_CHATROOM)
export const updateChatroomMap = createAction<{
  [key: string]: ChatroomObject
}>(GET_CHATROOM_MAP)
// 更新当前聊天室信息
export const updateChatroom = createAction<ChatroomInfo>(UPDATE_CHATROOM)
export const enterChatroom = createAction<{
    chatroomId: number, chatroom: ChatroomInstance
}>(ENTER_CHATROOM)
export const exitChatroom = createAction(EXIT_CHATROOM)
// 更新当前聊天室消息
export const appendRawMessagesAction = createAction<Message[]>(APPEND_RAW_MESSAGE)
export const prependRawMessagesAction = createAction<Message[]>(PREPEND_RAW_MESSAGE)
export const appendMessageListAction = createAction<Message[]>(APPEND_MESSAGE_LIST)
export const prependMessageListAction = createAction<Message[]>(PREPEND_MESSAGE_LIST)
// 动态修改展示的消息
export const modifyMessageAction = createAction<Partial<Message> & { time: number }>(MODIFY_MESSAGE)
// 根据时间戳删除消息
export const removeMessageAction = createAction<string>(REMOVE_MESSAGE)
export const addMessageListTransformer = createAction<MessageTransformer>(ADD_MESSAGE_TRANSFORMER)
export const updateDynamicMessageList = createAction<void>(UPDATE_DYNAMIC_MESSAGE_LIST)
// 更新当前聊天室成员
export const updateChatroomMemberList = createAction<{
  type: 'put' | 'destroy'
  members?: Member[]
}>(UPDATE_CHATROOM_MEMBER)
export const updateMyInfo = createAction<UserInfo>(UPDATE_MY_INFO)
export const updateUserInfo = createAction<UserInfo[]>(UPDATE_USER_INFO)
export const updateScrollPosition = createAction<number>(UPDATE_SCROLL_POSITION)
export const updateReplyingMessage = createAction<Message | null>(UPDATE_REPLYING_MESSAGE)
export const updateQuestionMode = createAction<boolean>(UPDATE_QUESTION_MODE)
export const setListRef = createAction<VirtuosoMethods | undefined>(SET_LIST_REF)
export const updateLastReadPosition = createAction<number>(UPDATE_LAST_READ_POSITION)
export const updateVisibleMessageCount = createAction<number>(UPDATE_VISIBLE_MESSAGE_COUNT)
export const updateTotalVisibleMessageCount = createAction<number>(UPDATE_TOTAL_VISIBLE_MESSAGE_COUNT)
export const updateSpecializedMessageFilter = createAction<MessageFilter>(UPDATE_SPECIALIZED_MESSAGE_FILTER)
export const updateExternalMessageFilter = createAction<MessageFilter>(UPDATE_EXTERNAL_MESSAGE_FILTER)
